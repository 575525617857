import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {Col} from "react-bootstrap"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Scrollchor from 'react-scrollchor'
import isEmpty from 'lodash/isEmpty'

const Box = ({heading, subheading, type, warningType, controls, content, active, md, sm, title}) => {
    let controls_, icon_ = null;
    if (warningType) {
        let icon = null;
        switch (warningType){
            case 'danger':
                icon = <FontAwesomeIcon icon="exclamation-circle" size="3x"/>
                break
            case 'warning':
                icon = <FontAwesomeIcon icon="exclamation" size="2x"/>
                break
            case 'normal':
                icon = <FontAwesomeIcon icon="check" size="2x"/>
                break
            default:
                icon = null
        }
        icon_ = icon ? <div className={classnames("box-icons", warningType)}>{icon}</div> : null;
    }

    if (!isEmpty(controls)) {
        controls_ = controls.map((item, index) => {
            const inner = item.href ? <Scrollchor to={item.href}>{item.label}</Scrollchor> : item.label;
            return <div key={index} onClick={item.onClick}>{inner}</div>
        })
        controls_ = <div className="box-controls">
            {controls_}
        </div>
    }

    return (
        <Col md={md} sm={sm}>
            <div className={classnames("box", type, active ? "active" : "", icon_ && controls_ ? "multiple" : null)}>
                <div className="box-content" title={title}>
                    <h3 title={heading}>{heading}</h3>
                    {subheading ? <small>{subheading}</small> : null}
                    {content}
                </div>
                <div className="box-icons-controls">
                    {icon_}
                    {controls_}
                </div>
            </div>
        </Col>
    );
}

Box.propTypes = {
    heading: PropTypes.string.isRequired,
    subheading: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    warningType: PropTypes.string.isRequired,
    controls: PropTypes.array,
    content: PropTypes.object,
    title: PropTypes.string
}

Box.defaultProps = {
    warningType: '',
    subheading: '',
    content: null,
    md: 4,
    sm: 6,
    title: ''
}

export default Box;