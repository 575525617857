import {
    REQUEST_WAREHOUSE,
    RECEIVE_WAREHOUSE,
    ERROR_WAREHOUSE,
    RELOAD_WAREHOUSE
} from '../constants/types'
import {initialFetchState} from '../constants/initialState'

export default (state = initialFetchState, action = {}) => {
    switch (action.type) {
        case REQUEST_WAREHOUSE:
            return Object.assign({}, state, {
                isFetching: true,
                message: '',
                errors: []
            })
        case RECEIVE_WAREHOUSE:
            return Object.assign({}, state, {
                isFetching: false,
                items: action.data,
                shouldReload: false,
                message: action.message
            })
        case ERROR_WAREHOUSE:
            return Object.assign({}, state, {
                isFetching: false,
                shouldReload: false,
                errors: action.errors
            })
        case RELOAD_WAREHOUSE:
            return Object.assign({}, state, {
                shouldReload: true,
            })
        default:
            return state;
    }
}