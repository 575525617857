import axios from 'axios'
import {
    REQUEST_UNIT_CATEGORIES,
    RECEIVE_UNIT_CATEGORIES,
    ERROR_UNIT_CATEGORIES
} from '../constants/types'
import  {fetchUrl} from '../constants/constants'

function requestUnitCategories() {
    return {
        type: REQUEST_UNIT_CATEGORIES
    };
}

function receiveUnitCategories(data, message = '') {
    return {
        type: RECEIVE_UNIT_CATEGORIES,
        unitCategories: data,
        message: message
    }
}

function failedUnitCategories(data) {
    return {
        type: ERROR_UNIT_CATEGORIES,
        errors: data
    }
}

function shouldFetchUnitCategories(state, basedOnData = false) {
    const fetch = state.unitCategories;
    if (!fetch) {
        return true
    } else if (fetch.isFetching) {
        return false
    } else if (basedOnData) {
        if(fetch.items.length == 0){
            return true
        }else{
            return false
        }
    } else {
        return true
    }
}

function doFetchUnitCategories(data, request) {
    return dispatch => {
        dispatch(requestUnitCategories());
        return axios.post(fetchUrl + request, data).then(res => {
            const response = res.data;
                if (response.data.success) {
                    dispatch(receiveUnitCategories(response.data.data, response.message));
                } else {
                    dispatch(failedUnitCategories([response.message]))
                }
            }).catch(err => {
                return dispatch(failedUnitCategories(["Neočekávaná chyba"]))
            })
    }
}

function fetchUnitCategories(type, data = {}) {
    let request;
    let compareData;
    switch (type) {
        case 'get':
            request = 'getUnitCategories';
            compareData = true;
            break;
        default:
            request = 'getUnitCategories';
            compareData = false;
    }
    return (dispatch, getState) => {
        if (shouldFetchUnitCategories(getState(), compareData)) {
            return dispatch(doFetchUnitCategories(data, request))
        } else {
            return Promise.resolve()
        }
    }
}

export function getUnitCategories(data = {}){
    return fetchUnitCategories('get', data);
}