import axios from 'axios'
import {
    REQUEST_SESSIONS,
    RECEIVE_SESSIONS,
    ERROR_SESSIONS,
    SET_ACTIVE_SESSION
} from '../constants/types'
import  {fetchUrl} from '../constants/constants'

import {reloadRecipes} from './RecipesActions'
import {reloadWarehouseStatus} from './WarehouseStatusActions'
import {reloadWarehouse} from './WarehouseActions'
import {reloadOrders} from './OrdersActions'

function setActiveSession(data) {
    return {
        type: SET_ACTIVE_SESSION,
        sessions: data
    };
}

function requestSessions() {
    return {
        type: REQUEST_SESSIONS
    };
}

function receiveSessions(data, message = '') {
    return {
        type: RECEIVE_SESSIONS,
        sessions: data,
        message: message
    }
}

function failedSessions(data) {
    return {
        type: ERROR_SESSIONS,
        errors: data
    }
}

function shouldFetchSessions(state, basedOnData = false) {
    const fetch = state.sessions;
    if (!fetch) {
        return true
    } else if (fetch.isFetching) {
        return false
    } else if (basedOnData) {
        if (fetch.items.length == 0) {
            return true
        } else {
            return false
        }
    } else {
        return true
    }
}

function doFetchSessions(data, request) {
    return dispatch => {
        if (request != "getReportSession") {
            dispatch(requestSessions());
        }
        return axios.post(fetchUrl + request, data).then(res => {
            const response = res.data;
            if(request != 'getReportSession'){
                if (response.data.success) {
                    dispatch(receiveSessions(response.data.data, response.message));
                    var sessions = response.data.data;
                    for (var i = 0; i < sessions.length; i++) {
                        if (sessions[i].pre_active || sessions[i].active) {
                            dispatch(setActiveSession(sessions[i]));
                            break;
                        }
                    }
                    if(request == 'finalizeSession' || request == 'activateSession' || request == 'deactivateSession'){
                        dispatch(reloadRecipes())
                        dispatch(reloadWarehouseStatus())
                        dispatch(reloadWarehouse())
                        dispatch(reloadOrders())
                    }
                } else {
                    dispatch(failedSessions([response.message]))
                }
            }else{
                if (response.data.success) {
                    var popUp = window.open(response.data.data.target);
                    if (popUp == null || typeof(popUp) == 'undefined') {
                        alert('Otevření souboru bylo zablokováno prohlížečem. Povolte otevírání PopUp oken pro tuto doménu a akci opakujte');
                    } else {
                        popUp.focus();
                    }
                }
            }

        }).catch(err => {
            return dispatch(failedSessions(["Neočekávaná chyba"]))
        })
    }
}

function fetchSessions(type, data = {}) {
    let request;
    let compareData;
    switch (type) {
        case 'get':
            request = 'getSessions';
            compareData = true;
            break;
        case 'update':
            request = 'updateSession';
            compareData = false;
            break;
        case 'add':
            request = 'addSession';
            compareData = false;
            break;
        case 'activate':
            request = 'activateSession';
            compareData = false;
            break;
        case 'deactivate':
            request = 'deactivateSession';
            compareData = false;
            break;
        case 'finalize':
            request = 'finalizeSession';
            compareData = false;
            break;
        case 'report':
            request = 'getReportSession';
            compareData = false;
            break;
        default:
            request = 'getSessions';
            compareData = false;
    }
    return (dispatch, getState) => {
        if (shouldFetchSessions(getState(), compareData)) {
            return dispatch(doFetchSessions(data, request))
        } else {
            return Promise.resolve()
        }
    }
}

export function getSessions(data = {}) {
    return fetchSessions('get', data);
}

export function updateSession(data = {}) {
    return fetchSessions('update', data);
}

export function addSession(data = {}) {
    return fetchSessions('add', data);
}

export function activateSession(data = {}) {
    return fetchSessions('activate', data);
}

export function deactivateSession(data = {}) {
    return fetchSessions('deactivate', data);
}

export function finalizeSession(data = {}) {
    return fetchSessions('finalize', data);
}

export function getReportSession(data = {}) {
    return fetchSessions('report', data);
}