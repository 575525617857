import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {Col} from "react-bootstrap"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Creatable from 'react-select/creatable'

const BoxAddRecipe = ({dishName, items, units, ingredients, instructions, button, active, reset, onChangeDish, onSelectIngredient, onChangeIngredient, onSelectUnit, onDeleteIngredient, onAddIngredient, onIngredientCreate}) => {
    const ingredientsRows = items.map((item, index) => {
            const id_unit_category = item.ingredient.id_unit_category;
            const ingredientUnits = units.map((itm, idx) => {
                const enabled = id_unit_category ? id_unit_category == itm.id_unit_category ? true : false : true;
                return <button
                    key={idx}
                    className={classnames("unit-button btn btn-theme", item.unit == itm.id ? "active" : "")}
                    title={itm.name}
                    onClick={enabled ? () => onSelectUnit(itm.id, index) : () => void(0)}
                    disabled={!enabled}
                >
                    {itm.unit}
                </button>

            })

            let unusedIngredients = ingredients.slice(0); //create copy because of reference

            for (var j = 0; j < items.length; j++) {
                const index = unusedIngredients.findIndex(i => i.value == items[j].ingredient.value);
                if (index != -1 && item.ingredient.value != items[j].ingredient.value) {
                    unusedIngredients.splice(index, 1);
                }
            }

            return <div key={index} className="ingredients-row">
                <div className="ingredient-name">
                    <Creatable
                        key="select"
                        name={index}
                        placeholder="Surovina"
                        title="Surovina"
                        value={item.ingredient}
                        onChange={(e) => onSelectIngredient(e, index)}
                        options={unusedIngredients}
                        isDisabled={!item.is_new}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        onCreateOption={(e) => onIngredientCreate(e, index)}
                    />
                </div>
                <div>
                    <input
                        className="form-control"
                        type="number"
                        placeholder="Množství"
                        title="Množství"
                        value={item.quantity}
                        onChange={(e) => onChangeIngredient(e, index)}
                        name="quantity"
                        min="0.1"
                        step="0.1"
                    />
                </div>
                <div className="ingredient-units">
                    {ingredientUnits}
                </div>
                <div className="remove-ingredient">
                    <button
                        onClick={item.is_new ? () => onAddIngredient(item.ingredient.label, index) : () => onDeleteIngredient(item.ingredient.label, index)}
                        className={classnames("btn btn-theme", item.is_new ? "inverse" : "")}
                    >{item.is_new ? "přidat" : "odebrat"}
                    </button>
                </div>
            </div>
        }
    );

    return (
        <Col sm={12}>
            <div className={classnames("box", "no-hover", "vertical", active ? "active" : "")}>
                <div className="box-content">
                    {
                        active ?
                            <div className="reset-box" onClick={reset} title="Zrušit">
                                <FontAwesomeIcon icon="times"/>
                            </div>
                            :
                            null
                    }
                    <div className="box-inputs no-flex">
                        <div>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Název jídla"
                                title="Název jídla"
                                value={dishName}
                                onChange={onChangeDish}
                                name="dishName"
                            />
                        </div>
                        <div className="box-ingredients">
                            {ingredientsRows}
                        </div>
                        <div>
                            <textarea
                                className="form-control"
                                placeholder="Postup přípravy"
                                title="Postup přípravy"
                                onChange={onChangeDish}
                                name="instructions"
                                value={instructions}
                            >
                            </textarea>
                        </div>
                    </div>
                </div>
                <div className="box-icons-controls">
                    <div className="box-controls">
                        <div onClick={button.onClick}>{button.label}</div>
                    </div>
                </div>
            </div>
        </Col>
    );
}

BoxAddRecipe.propTypes = {
    ingredients: PropTypes.array.isRequired,
    units: PropTypes.array.isRequired,
    items: PropTypes.array.isRequired,
    button: PropTypes.object.isRequired,
    reset: PropTypes.func.isRequired,
    onChangeDish: PropTypes.func.isRequired,
    onSelectIngredient: PropTypes.func.isRequired,
    onChangeIngredient: PropTypes.func.isRequired,
    onSelectUnit: PropTypes.func.isRequired,
    onDeleteIngredient: PropTypes.func.isRequired,
    onAddIngredient: PropTypes.func.isRequired,
    onIngredientCreate: PropTypes.func.isRequired,
}

BoxAddRecipe.defaultProps = {
    units: [],
    ingredients: [],
}

export default BoxAddRecipe;