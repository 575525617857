import {
    REQUEST_ORDERS,
    RECEIVE_ORDERS,
    ERROR_ORDERS,
    RELOAD_ORDERS
} from '../constants/types'
import { initialFetchState } from '../constants/initialState'


export default (state = initialFetchState, action = {}) => {
    switch(action.type) {
        case REQUEST_ORDERS:
            return Object.assign({}, state, {
                isFetching: true,
                message: '',
                errors: []
            })
        case RECEIVE_ORDERS:
            return Object.assign({}, state, {
                isFetching: false,
                items: action.orders,
                shouldReload: false,
                message: action.message
            })
        case ERROR_ORDERS:
            return Object.assign({}, state, {
                isFetching: false,
                shouldReload: false,
                errors: action.errors
            })
        case RELOAD_ORDERS:
            return Object.assign({}, state, {
                shouldReload: true,
            })
        default: return state;
    }
}