import axios from 'axios'
import {
    REQUEST_UNITS,
    RECEIVE_UNITS,
    ERROR_UNITS
} from '../constants/types'
import  {fetchUrl} from '../constants/constants'

function requestUnits() {
    return {
        type: REQUEST_UNITS
    };
}

function receiveUnits(data, message = '') {
    return {
        type: RECEIVE_UNITS,
        units: data,
        message: message
    }
}

function failedUnits(data) {
    return {
        type: ERROR_UNITS,
        errors: data
    }
}

function shouldFetchUnits(state, basedOnData = false) {
    const fetch = state.units;
    if (!fetch) {
        return true
    } else if (fetch.isFetching) {
        return false
    } else if (basedOnData) {
        if(fetch.items.length == 0){
            return true
        }else{
            return false
        }
    } else {
        return true
    }
}

function doFetchUnits(data, request) {
    return dispatch => {
        dispatch(requestUnits());
        return axios.post(fetchUrl + request, data).then(res => {
            const response = res.data;
                if (response.data.success) {
                    dispatch(receiveUnits(response.data.data, response.message));
                } else {
                    dispatch(failedUnits([response.message]))
                }
            }).catch(err => {
                return dispatch(failedUnits(["Neočekávaná chyba"]))
            })
    }
}

function fetchUnits(type, data = {}) {
    let request;
    let compareData;
    switch (type) {
        case 'get':
            request = 'getUnits';
            compareData = true;
            break;
        default:
            request = 'getUnits';
            compareData = false;
    }
    return (dispatch, getState) => {
        if (shouldFetchUnits(getState(), compareData)) {
            return dispatch(doFetchUnits(data, request))
        } else {
            return Promise.resolve()
        }
    }
}

export function getUnits(data = {}){
    return fetchUnits('get', data);
}