import React, {Component} from 'react'
import {Link, Redirect} from 'react-router-dom'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {validateInput} from '../../utils/validations/auth'
import {login} from '../../actions/AuthActions'
import {homepage} from '../../constants/constants'
import AuthInput from './AuthInput'
import {ToastDanger} from 'react-toastr-basic'
import {Row, Col} from "react-bootstrap"
var sha512 = require('sha512')

class LoginForm extends Component {

    componentDidUpdate(prevProps, prevState){
        if(this.state.counter != prevState.counter){
            const {errors} = this.state;
            Object.keys(errors).map(key =>
                ToastDanger(errors[key])
            );
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            identifier: '',
            password: '',
            errors: {},
            isLoading: false,
            counter: 0,
            redir: false
        };
    }

    isValid() {
        const {errors, isValid} = validateInput(this.state);

        if (!isValid) {
            this.setState({errors});
        }
        return isValid;
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({counter: this.state.counter + 1});
        if (this.isValid()) {
            this.setState({errors: {}, isLoading: true});
            let loginData = {
                identifier: this.state.identifier,
                password: sha512(this.state.password)
            }
            loginData.password = loginData.password.toString('hex');
            this.props.login(loginData).then(
                (res) => {
                    if (res !== true) {
                        this.setState({errors: {server: res}, isLoading: false, counter: 0});
                    } else {
                        this.setState({redir: homepage})
                    }
                },
                (err) => this.setState({errors: {server: err}, isLoading: false})
            );
        }
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        if(this.redir){
            return <Redirect to={this.state.redir}/>
        }
        const {errors, identifier, password, isLoading} = this.state;
        return (
            <form className="auth-form" onSubmit={(e) => this.onSubmit(e)}>
                <h1>Přihlášení</h1>

                <AuthInput
                    field="identifier"
                    value={identifier}
                    label="Email"
                    type="email"
                    onChange={(e) => this.onChange(e)}
                    icon="user"
                    errors={errors}
                />

                <AuthInput
                    field="password"
                    value={password}
                    label="Heslo"
                    type="password"
                    onChange={(e) => this.onChange(e)}
                    icon="lock"
                    errors={errors}
                />

                <div className="button-row">
                    <Row className="margin-right-0">
                        <Col lg={5} md={6} sm={5} xs={6}>
                            <button className="btn" disabled={isLoading}>Přihlásit</button>
                        </Col>
                        <Col lg={7} md={6} sm={7} xs={6}>
                            <div className="button-side-text">
                                <Link to="/zapomenute-heslo">
                                    Zapomněli jste heslo?
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </div>
            </form>
        );
    }
}

LoginForm.propTypes = {
    login: PropTypes.func.isRequired
};

LoginForm.contextTypes = {
    router: PropTypes.object.isRequired
}

function mapDispatchToProps(dispatch) {
    return {
        login: bindActionCreators(login, dispatch)
    };
}

export default connect(
    null,
    mapDispatchToProps
)(LoginForm);