import React from 'react'
import PropTypes from 'prop-types'
import {Col} from "react-bootstrap"
import classnames from 'classnames'

const IngredientsList = ({items, md, sm, size}) => {
    var count = size == "medium" ? 7 : 999;
    const list = items.map((item, index) => {
            if (index < count) {
                return <Col key={index} sm={sm} md={md}>
                    <div className="values">
                        <span className="number highlight">{item.quantity}</span>
                        <span className="unit">{item.unit}</span>
                    </div>
                    <div className="labels">
                        <span className="label">{item.ingredient}</span>
                    </div>
                </Col>
            }else if(index == count){
                return <Col key={index} sm={sm} md={md}>
                    <div className="values">
                        <span className="number highlight">&hellip;</span>
                        <span className="unit"></span>
                    </div>
                    <div className="labels">
                        <span className="label"></span>
                    </div>
                </Col>
            } else {
                return false;
            }
        }
    );

    return (
        <div className={classnames("row ingredients-list", size)}>
            {list}
        </div>
    );
}

IngredientsList.propTypes = {
    items: PropTypes.array,
}

IngredientsList.defaultProps = {
    md: 6,
    sm: 12,
    size: "medium"
}

export default IngredientsList;