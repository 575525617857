import isEmpty from 'lodash/isEmpty'
import isInteger from 'lodash/isInteger'
import isNumber from 'lodash/isNumber'

export function validateInput(data) {
    let errors = {};
    if (!isInteger(data.selectedDish.value)) {
        errors.selectedDish = 'Nesprávná hodnota pole Jídlo';
    }

    if ((!isNumber(data.quantityValue) && data.quantityValue !== '') || data.quantityValue > 1000 || data.quantityValue < -1000) {
        errors.quantityValue = 'Nesprávná hodnota pole Množství - běžná porce';
    }

    if ((!isNumber(data.quantityValueChildren) && data.quantityValueChildren !== '') || data.quantityValueChildren > 1000 || data.quantityValueChildren < -1000) {
        errors.quantityValueChildren = 'Nesprávná hodnota pole Množství - malá porce';
    }

    if ((!isNumber(data.quantityValue) || data.quantityValue === 0) && (!isNumber(data.quantityValueChildren) || data.quantityValueChildren === 0)){
        errors.quantityValue = 'Minimálně jedna položka množství musí být zadána';
    }

    if(isNumber(data.quantityValue) && isNumber(data.quantityValueChildren)){
        if((data.quantityValue * data.quantityValueChildren) < 0){
            errors.quantityValue = 'V jednom kroku je možné buď přidávat nebo odebírat objednávky. Kombinace není možná.';
        }
    }

    return {
        errors,
        isValid: isEmpty(errors)
    };
}
export function validatePrintInput(data) {
    let errors = {};
    if (isEmpty(data.selectedOrders)) {
        errors.selectedOrders = 'Vyberte alespoň jednu položku pro tisk';
    }

    return {
        errors,
        isValid: isEmpty(errors)
    };
}