import validator from 'validator'
import isEmpty from 'lodash/isEmpty'

export function validateInput(data) {
    let errors = {};

    if (validator.isEmpty(data.nameValue)) {
        errors.nameValue = 'Vyplňte pole Název kategorie';
    }

    return {
        errors,
        isValid: isEmpty(errors)
    };
}