import axios from 'axios'
import {
    REQUEST_DISHES,
    RECEIVE_DISHES,
    ERROR_DISHES
} from '../constants/types'
import  {fetchUrl} from '../constants/constants'

function requestDishes() {
    return {
        type: REQUEST_DISHES
    };
}

export function receiveDishes(data, message = '') {
    return {
        type: RECEIVE_DISHES,
        dishes: data,
        message: message
    }
}

function failedDishes(data) {
    return {
        type: ERROR_DISHES,
        errors: data
    }
}

function shouldFetchDishes(state, basedOnData = false) {
    const fetch = state.dishes;
    if (!fetch) {
        return true
    } else if (fetch.isFetching) {
        return false
    } else if (basedOnData) {
        if(fetch.items.length == 0){
            return true
        }else{
            return false
        }
    } else {
        return true
    }
}

function doFetchDishes(data, request) {
    return dispatch => {
        dispatch(requestDishes());
        return axios.post(fetchUrl + request, data).then(res => {
            const response = res.data;
                if (response.data.success) {
                    dispatch(receiveDishes(response.data.data, response.message));
                } else {
                    dispatch(failedDishes([response.message]))
                }
            }).catch(err => {
                return dispatch(failedDishes(["Neočekávaná chyba"]))
            })
    }
}

function fetchDishes(type, data = {}) {
    let request;
    let compareData;
    switch (type) {
        case 'get':
            request = 'getDishes';
            compareData = false;
            break;
        default:
            request = 'getDishes';
            compareData = false;
    }
    return (dispatch, getState) => {
        if (shouldFetchDishes(getState(), compareData)) {
            return dispatch(doFetchDishes(data, request))
        } else {
            return Promise.resolve()
        }
    }
}

export function getDishes(data = {}){
    return fetchDishes('get', data);
}