import isEmpty from 'lodash/isEmpty'
import isInteger from 'lodash/isInteger'
import isNumber from 'lodash/isNumber'

export function validateInput(data) {
    const item = data.newItem;
    let errors = {};
    if (isEmpty(item.nameValue.label)) {
        errors.nameValue = 'Nesprávná hodnota pole Jídlo';
    }

    if (!isNumber(item.quantityValue) || item.quantityValue < 1) {
        errors.quantityValue = 'Nesprávná hodnota pole Množství';
    }

    if (!isNumber(item.priceValue) || item.priceValue < 0) {
        errors.priceValue = 'Nesprávná hodnota pole Cena';
    }

    if (item.wasteValue < 0) {
        errors.wasteValue = 'Nesprávná hodnota pole Odpad';
    }

    if (!isInteger(item.unitValue)) {
        errors.unit = 'Nesprávná hodnota pole Jednotka';
    }

    return {
        errors,
        isValid: isEmpty(errors)
    };
}
