import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {bindActionCreators} from 'redux'
import {validateInput} from '../../utils/validations/search'
import {setSearch} from '../../actions/SearchActions'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {navigation} from '../../constants/constants'

class Search extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            search: '',
            counter: 0,
            errors: {}
        }
        this.props.actions.setSearch(''); // nulování předchozího hledání a překliknutí menu
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.search.length && this.state.search.length == 0){ //smazání
            this.props.actions.setSearch('');
        }
    }

    search(e){
        e.preventDefault();
        this.setState({counter: this.state.counter + 1});
        if (this.isValid()) {
            this.setState({errors: {}});
            this.props.actions.setSearch(this.state.search);
        }
    }

    onChange(e){
        const value = e.target.value;
        this.setState({search: value});
        if(value.length > 2){
            this.props.actions.setSearch(value);
        }
    }

    isValid() {
        const {errors, isValid} = validateInput(this.state);

        if (!isValid) {
            this.setState({errors});
        }
        return isValid;
    }

    render() {
        if(navigation[this.props.navigation].search){
            return (
                <div className="search-field">
                    <input
                        type="text"
                        placeholder="vyhledat"
                        onChange={(e) => this.onChange(e)}
                        value={this.state.search}
                    />
                    <FontAwesomeIcon
                        icon="search"
                        size="1x"
                        className="icon-search"
                        onClick={(e) => this.search(e)}
                    />
                </div>
            );
        }else{
            return null;
        }
    }
}

Search.propTypes = {
    actions: PropTypes.object.isRequired,
    navigation: PropTypes.string.isRequired,
}

function mapStateToProps(state) {
    return {
        navigation: state.navigation
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({setSearch}, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Search);