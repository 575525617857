import React from 'react'
import PropTypes from 'prop-types'
import Quagga from 'quagga'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

class Scanner extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            message: '',
            wrongDecodedCnt: 0
        }
    }

    componentDidMount() {
        Quagga.init({
            inputStream: {
                type: "LiveStream",
                constraints: {
                    width: 640,
                    height: 480,
                    facingMode: "environment" // or user
                }
            },
            locator: {
                patchSize: "medium",
                halfSample: true
            },
            numOfWorkers: 2,
            decoder: {
                readers: ["ean_reader", "ean_8_reader"]
            },
            locate: true,
        }, function (err) {
            if (err) {
                alert("chyba při přístupu ke kameře");
                return;
            }
            Quagga.start();
        });
        Quagga.onDetected((data) => this._onDetected(data));
    }

    _getMedian(arr) {
        arr.sort((a, b) => a - b);
        const half = Math.floor(arr.length / 2);
        if (arr.length % 2 === 1) {
            return arr[half];
        }
        return (arr[half - 1] + arr[half]) / 2.0;
    }

    _onDetected(result) {
        const errors = result.codeResult.decodedCodes
            .filter(_ => _.error !== undefined)
            .map(_ => _.error);
        const median = this._getMedian(errors);
        if (median < 0.1) { // probably correct
            this.setState({message: "", wrongDecodedCnt: 0});
            this.props.onDetected(result.codeResult.code);
            this.handleClose();
        } else { // probably wrong
            const wrongDecodedCnt = this.state.wrongDecodedCnt + 1;
            this.setState({wrongDecodedCnt: wrongDecodedCnt}, ()=>{
                if(wrongDecodedCnt > 4){
                    this.setState({message: "Okolní podmínky pro čtení kódu nejsou ideální."});
                }
            });
        }
    }

    handleClose(){
        Quagga.stop();
        this.props.onClose();
    }

    render() {
        return (
            <div id="interactive" className="viewport barcode-viewport">
                <div className="close-icon-wrapper">
                    <FontAwesomeIcon icon={['far', 'times-circle']} className="close-icon" size="2x" onClick={() => this.handleClose()}/>
                    <FontAwesomeIcon icon={['fas', 'times-circle']} className="close-icon-hover" size="2x" onClick={() => this.handleClose()}/>
                </div>
                {this.state.message ? <div className="scanner-message">{this.state.message}</div> : null}
                <div className="diode">
                    <div className="laser"></div>
                </div>
            </div>
        )
    }
}

Scanner.propTypes = {
    onDetected: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
}

export default Scanner