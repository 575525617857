import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import {Row, Col} from "react-bootstrap"
import {editUser} from '../../actions/AuthActions'
import Header from "../common/Header"
import Navigation from "../common/Navigation"
import {setActiveMenuItem} from '../../actions/NavigationActions'
import {validateProfileInput} from '../../utils/validations/auth'
import {ToastDanger} from 'react-toastr-basic'
import {ToastSuccess} from 'react-toastr-basic'
var sha512 = require('sha512')

class ProfilePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            identifier: props.auth.user.identifier,
            name: props.auth.user.name,
            password: '',
            errors: {},
            isLoading: false,
            counter: 0,
            readonly: true
        };
        props.actions.setActiveMenuItem('profile');
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.counter != prevState.counter) {
            const {errors} = this.state;
            Object.keys(errors).map(key =>
                ToastDanger(errors[key])
            );
        }
    }

    isValid() {
        const {errors, isValid} = validateProfileInput(this.state);

        if (!isValid) {
            this.setState({errors});
        }
        return isValid;
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onHover(e) {
        this.setState({readonly: false});
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({counter: this.state.counter + 1});
        if (this.isValid()) {
            this.setState({errors: {}, isLoading: true});
            let userData = {
                identifier: this.state.identifier,
                name: this.state.name,
                password: sha512(this.state.password),
                id: this.props.auth.user.id
            }
            userData.password = userData.password.toString('hex');
            this.props.actions.editUser(userData).then(
                (res) => {
                    if (res !== true) {
                        this.setState({errors: {server: res}, isLoading: false, counter: 0, password: ''});
                    } else {
                        ToastSuccess("Uloženo");
                        this.setState({password: '', isLoading: false});
                    }
                },
                (err) => this.setState({errors: {server: err}, isLoading: false, password: ''})
            );
        }
    }

    render() {
        const {name, identifier, password, isLoading, readonly} = this.state;
        return (
            <div>
                <Navigation/>
                <div className="main-page">
                    <Header/>
                    <div className="main-content">
                        <Row>
                            <Col md={4}>
                                <form onSubmit={(e) => this.onSubmit(e)} className="profile-form">
                                    <h2>Základní údaje</h2>
                                    <label htmlFor="name">Jméno</label>
                                    <input
                                        type="text"
                                        placeholder="Jméno"
                                        name="name"
                                        id="name"
                                        className="form-control"
                                        value={name}
                                        onChange={(e) => this.onChange(e)}
                                    />
                                    <label htmlFor="identifier">Email</label>
                                    <input
                                        type="email"
                                        placeholder="e-mail"
                                        name="identifier"
                                        id="identifier"
                                        className="form-control"
                                        value={identifier}
                                        onChange={(e) => this.onChange(e)}
                                    />
                                    <label htmlFor="password">Heslo (pro ověření)</label>
                                    <input
                                        type="password"
                                        placeholder="heslo"
                                        name="password"
                                        id="password"
                                        autoComplete="false"
                                        readOnly={readonly}
                                        className="form-control"
                                        value={password}
                                        onChange={(e) => this.onChange(e)}
                                        onMouseOver={(e) => this.onHover(e)}
                                    />
                                    <div className="button-row">
                                        <Row className="margin-right-0">
                                            <Col lg={5} md={6} sm={5} xs={6}>
                                                <button className="btn" disabled={isLoading}>Uložit</button>
                                            </Col>
                                            <Col lg={7} md={6} sm={7} xs={6}>
                                                <div className="button-side-text">
                                                    <Link to="/zmenit-heslo">
                                                        Změnit heslo
                                                    </Link>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </form>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

ProfilePage.propTypes = {
    actions: PropTypes.object.isRequired,
}


function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({setActiveMenuItem, editUser}, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfilePage);