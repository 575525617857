import React, { Component } from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {validatePassword} from '../../utils/validations/auth'
import {changePassword} from '../../actions/AuthActions'
import AuthInput from './AuthInput'
import {ToastDanger} from 'react-toastr-basic'
import {ToastSuccess} from 'react-toastr-basic'
import {Row, Col} from "react-bootstrap"
import {Redirect} from "react-router-dom";
var sha512 = require('sha512')

class ChangeForm extends Component {
    componentDidUpdate(prevProps, prevState){
        if(this.state.counter != prevState.counter){
            const {errors} = this.state;
            Object.keys(errors).map(key =>
                ToastDanger(errors[key])
            );
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            password: '',
            passwordCheck: '',
            passwordOld: '',
            errors: {},
            isLoading: false,
            counter: 0,
            redir: false
        };
    }

    isValid() {
        const {errors, isValid} = validatePassword(this.state, true);
        if (!isValid) {
            this.setState({errors});
        }
        return isValid;
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({counter: this.state.counter + 1});
        if (this.isValid()) {
            this.setState({errors: {}, isLoading: true});
            let data = {
                password: sha512(this.state.password),
                passwordOld: sha512(this.state.passwordOld),
                id: this.props.auth.user.id
            };
            data.password = data.password.toString('hex');
            data.passwordOld = data.passwordOld.toString('hex');
            this.props.changePassword(data).then(
                (res) => {
                    if (res !== true) {
                        this.setState({errors: {server: res}, isLoading: false, counter: 0});
                    } else {
                        ToastSuccess("Heslo bylo úspěšně změněno");
                        setTimeout(() => {
                            this.setState({redir: "/profil"})
                        }, 2500);
                    }
                },
                (err) => this.setState({errors: {server: err}, isLoading: false})
            );
        }
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        if(this.state.redir){
            return <Redirect to={this.state.redir}/>
        }
        const {errors, password, passwordCheck, passwordOld, isLoading} = this.state;
        return (
            <form className="auth-form" onSubmit={(e) => this.onSubmit(e)}>
                <h1>Změnit heslo</h1>

                <AuthInput
                    field="passwordOld"
                    value={passwordOld}
                    label="Současné heslo"
                    type="password"
                    onChange={(e) => this.onChange(e)}
                    icon="lock-open"
                    errors={errors}
                />

                <AuthInput
                    field="password"
                    value={password}
                    label="Nové heslo"
                    type="password"
                    onChange={(e) => this.onChange(e)}
                    icon="lock"
                    errors={errors}
                />

                <AuthInput
                    field="passwordCheck"
                    value={passwordCheck}
                    label="Potvrzení nového hesla"
                    type="password"
                    onChange={(e) => this.onChange(e)}
                    icon="lock"
                    errors={errors}
                />

                <div className="button-row">
                    <Row className="margin-right-0">
                        <Col lg={6} md={7} sm={6} xs={6}>
                            <button className="btn" disabled={isLoading}>Změnit</button>
                        </Col>
                    </Row>
                </div>
            </form>
        );
    }
}

ChangeForm.propTypes = {
    changePassword: PropTypes.func.isRequired
};

ChangeForm.contextTypes = {
    router: PropTypes.object.isRequired
}

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changePassword: bindActionCreators(changePassword, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangeForm);