import React from 'react'
import PropTypes from 'prop-types'
import { Image } from "react-bootstrap"
import classnames from 'classnames'

const ThemeDesign = ({text}) => {
    const _text = text ? <h2>{text}</h2> : null;

    return (
        <div className={classnames("box-theme-inner", text ? "has-text" : "")}>
            <Image src="/assets/img/logo/logo.png"/>
            {_text}
        </div>
    );
}

ThemeDesign.propTypes = {
    text: PropTypes.string
}

export default ThemeDesign;