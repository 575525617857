import {
    REQUEST_LOG,
    RECEIVE_LOG,
    ERROR_LOG
} from '../constants/types'
import { initialFetchState } from '../constants/initialState'


export default (state = initialFetchState, action = {}) => {
    switch(action.type) {
        case REQUEST_LOG:
            return Object.assign({}, state, {
                isFetching: true,
                message: '',
                errors: []
            })
        case RECEIVE_LOG:
            return Object.assign({}, state, {
                isFetching: false,
                items: action.log,
                message: action.message
            })
        case ERROR_LOG:
            return Object.assign({}, state, {
                isFetching: false,
                errors: action.errors
            })
        default: return state;
    }
}