import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import PropTypes from 'prop-types'
import Header from "../common/Header"
import Navigation from "../common/Navigation"
import OrderBox from "../common/OrderBox"
import BoxAddSimple from "../common/BoxAddSimple"
import Spinner from "../common/Spinner"
import Empty from "../common/Empty"
import {Row, Col} from "react-bootstrap"
import {setActiveMenuItem} from '../../actions/NavigationActions'
import {validateInput, validatePrintInput} from '../../utils/validations/orders'
import {confirm} from '../../utils/confirm'
import {ToastDanger} from 'react-toastr-basic'
import {ToastSuccess} from 'react-toastr-basic'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Switch from "react-switch"
import classnames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import {
    addOrder,
    deleteOrder,
    deleteAllOrders,
    printOrders,
    getOrders
} from '../../actions/OrdersActions';

import {getDishes} from '../../actions/DishesActions';

class Orders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDish: '',
            quantityValue: '',
            quantityValueChildren: '',
            newLabel: 'přidat',
            errors: [],
            counter: 0,
            printView: false,
            selectAll: false,
            selectedOrders: []
        }

        props.actions.setActiveMenuItem('orders');
        props.actions.getDishes();
        props.actions.getOrders();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.counter != prevState.counter) {
            const {errors} = this.state;
            Object.keys(errors).map(key =>
                ToastDanger(errors[key])
            );
        }
        if (this.props != prevProps) {
            const {errors, message} = this.props.orders;
            if (errors) {
                errors.map((item) =>
                    ToastDanger(item)
                );
            }
            if (message && message != prevProps.orders.message) {
                ToastSuccess(message);
            }
        }
    }

    isValid() {
        const {errors, isValid} = validateInput(this.state);
        if (!isValid) {
            this.setState({errors});
        }
        return isValid;
    }

    isPrintValid() {
        const {errors, isValid} = validatePrintInput(this.state);
        if (!isValid) {
            this.setState({errors});
        }
        return isValid;
    }

    onClickDelete(item, is_children) {
        if(is_children && item.cnt_children === 0){
            let errors = {}
            errors.quantityValueChildren = "Není co smazat"
            this.setState({errors, counter: this.state.counter + 1})
            return
        }
        if(!is_children && item.cnt === 0){
            let errors = {}
            errors.quantityValue = "není co smazat"
            this.setState({errors, counter: this.state.counter + 1})
            return
        }
        this.deleteItem(item.id_dish, is_children ? 0 : 1, is_children ? 1 : 0)
    }

    onClickAdd(item, is_children) {
        this.addItem(item.id_dish, is_children ? 0 : 1, is_children ? 1 : 0)
    }


    onClickNew(e) {
        this.setState({counter: this.state.counter + 1});
        if (this.isValid()) {
            const {quantityValue, quantityValueChildren} = this.state
            if (quantityValue > 0 || quantityValueChildren > 0) {
                this.addItem(this.state.selectedDish.value, quantityValue || 0, quantityValueChildren || 0);
                this.resetState();
            } else {
                confirm('delete', this.state.selectedDish.label, () => this.deleteItem(this.state.selectedDish.value, quantityValue || 0, quantityValueChildren || 0), () => void(0));
            }
        }
    }

    onInputChange(e) {
        if (e.target.name == 'quantityValue' || e.target.name == 'quantityValueChildren') {
            if (e.target.value < 0) {
                this.setState({newLabel: "odebrat"})
            } else {
                this.setState({newLabel: "přidat"})
            }
        }
        this.setState({[e.target.name]: parseInt(e.target.value)});
    }

    onSelect(item) {
        this.setState({selectedDish: item});
    }

    onChangePrint() {
        this.setState({printView: !this.state.printView});
    }

    onPrintButtonClick(e) {
        this.setState({counter: this.state.counter + 1});
        if (this.isPrintValid()) {
            this.resetState();
            this.props.actions.printOrders({orders: this.state.selectedOrders, printType: parseInt(e.target.name)})
        }
    }

    onClickSelectAll() {
        const selected = this.state.selectAll ? [] : this.props.orders.items.map((item) => item.id)
        this.setState({selectAll: !this.state.selectAll, selectedOrders: selected});
    }

    onClickSelect(id) {
        let selectedOrders = this.state.selectedOrders;
        const index = selectedOrders.findIndex(i => i == id);
        if (index != -1) {
            selectedOrders.splice(index, 1);
        } else {
            selectedOrders.push(id);
        }
        const selectAll = isEmpty(selectedOrders) && this.state.selectAll ? false : this.state.selectAll;
        this.setState({selectedOrders: selectedOrders, selectAll: selectAll});
    }

    onClickReset() {
        this.resetState();
    }

    deleteItem(id, quantity, quantityChildren) {
        this.props.actions.deleteOrder({dish: id, quantity: quantity, quantityChildren: quantityChildren});
        this.resetState();
    }

    onClickDeleteAll(){
        confirm('deleteAll', "tato akce je nevratná", () => this.deleteAll(), () => void(0));
    }

    deleteAll(){
        this.props.actions.deleteAllOrders()
    }

    addItem(id, quantity, quantityChildren) {
        this.props.actions.addOrder({dish: id, quantity: quantity, quantityChildren: quantityChildren})
    }

    resetState() {
        this.setState({
            selectedDish: '',
            quantityValue: '',
            quantityValueChildren: '',
            newLabel: 'přidat',
            errors: [],
            counter: 0,
        });
    }

    render() {
        const {selectedDish, quantityValue, quantityValueChildren, newLabel, printView} = this.state;
        const {orders, search} = this.props;
        const items = search ?
            orders.items.filter(item => item.name.toLocaleLowerCase().includes(search.toLowerCase()))
            :
            orders.items;
        const boxes = items.length ? items.map(item => {
            const controls =
                {
                    print: {
                        label: <FontAwesomeIcon
                            icon={this.state.selectedOrders.includes(item.id) ? ['far', 'check-circle'] : "circle"}
                            size="2x"/>,
                        onClick: () => this.onClickSelect(item.id)
                    },
                    count: [
                        {
                            label: <FontAwesomeIcon icon="plus"/>,
                            onClick: (is_children) => this.onClickAdd(item, is_children)
                        },
                        {
                            label: <FontAwesomeIcon icon="minus"/>,
                            onClick: (is_children) => this.onClickDelete(item, is_children)
                        }
                    ]
                }

                const missing = item.missing || 'žádná surovina';

            return (
                <OrderBox
                    key={item.id}
                    heading={item.name}
                    subheading={"Chybějící surovina: " + missing}
                    warningType={item.status}
                    printView={printView}
                    controls={controls}
                    count={item.cnt}
                    countChildren={item.cnt_children}
                />
            )
        }) : orders.isFetching ? null : <Empty/>;

        const switchButton = <div className="switch-wrapper">
            <label htmlFor="print-switch">
                <Switch
                    onChange={() => this.onChangePrint()}
                    checked={printView}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    offColor="#CCC"
                    onColor="#9F0F30"
                    height={20}
                    width={40}
                    activeBoxShadow="0 0 2px 3px #9F0F30"
                    id="print-switch"
                />
                <span>Režim pro tisk</span>
            </label>
        </div>

        const filterButtons = <div>
            <button
                type="button"
                className="btn btn-theme"
                role="radio"
                name="1"
                onClick={(e) => this.onPrintButtonClick(e)}
            >vytisknout dle surovin
            </button>
            <button
                type="button"
                className="btn btn-theme"
                role="radio"
                name="2"
                onClick={(e) => this.onPrintButtonClick(e)}
            >vytisknout dle receptu
            </button>
        </div>

        const adittionalButtons = <div>
            <button
                type="button"
                className={classnames("btn btn-theme", this.state.selectAll ? "active" : "")}
                role="radio"
                name="btn1"
                onClick={() => this.onClickSelectAll()}
            >vybrat vše
            </button>
        </div>

        return (
            <div>
                <Navigation/>
                <div className="main-page">
                    <Header/>
                    <div className="main-content">
                        <Spinner visible={orders.isFetching}/>
                        <Row className="controls-row">
                            <Col xs={12}>
                                <div className="controls-row-wrapper has-switch">
                                    {switchButton}
                                    {
                                        printView ?
                                            <div className="filters-wrapper">
                                                {filterButtons}
                                                {adittionalButtons}
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            </Col>
                        </Row>

                        {
                            printView
                                ?
                                null
                                :
                                <Row>
                                    <BoxAddSimple
                                        active={false}
                                        fields={[
                                            {
                                                type: 'select',
                                                label: 'Vybrat jídlo',
                                                value: selectedDish,
                                                name: 'selectedDish',
                                                data: this.props.dishes.isFetching ? [] : this.props.dishes.items,
                                                onChange: (e) => this.onSelect(e)
                                            },
                                            {
                                                type: 'number',
                                                label: 'Množství - běžná porce',
                                                value: quantityValue,
                                                name: 'quantityValue',
                                                onChange: (e) => this.onInputChange(e)
                                            },
                                            {
                                                type: 'number',
                                                label: 'Množství - malá porce',
                                                value: quantityValueChildren,
                                                name: 'quantityValueChildren',
                                                onChange: (e) => this.onInputChange(e)
                                            }
                                        ]}
                                        button={{label: newLabel, onClick: (e) => this.onClickNew(e)}}
                                        reset={() => this.onClickReset()}
                                    />
                                </Row>
                        }

                        <Row>
                            {boxes}
                        </Row>
                        {
                            !isEmpty(items) && this.props.activeSession.pre_active ?
                                <Row>
                                    <button
                                        type="button"
                                        className="btn btn-theme inverse btn-delete-all"
                                        onClick={() => this.onClickDeleteAll()}
                                    >smazat všechny objednávky
                                    </button>
                                </Row>
                                :
                                null
                        }
                    </div>
                </div>
            </div>
        );
    }
}

Orders.propTypes = {
    actions: PropTypes.object.isRequired,
    activeSession: PropTypes.object.isRequired
}


function mapStateToProps(state) {
    return {
        orders: state.orders,
        search: state.search,
        dishes: state.dishes,
        activeSession: state.activeSession
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            setActiveMenuItem,
            getOrders,
            addOrder,
            deleteOrder,
            deleteAllOrders,
            getDishes,
            printOrders
        }, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Orders);