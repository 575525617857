import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import PropTypes from 'prop-types'
import Header from "../common/Header"
import Navigation from "../common/Navigation"
import Box from "../common/Box"
import BoxAddSimple from "../common/BoxAddSimple"
import Spinner from "../common/Spinner"
import Empty from "../common/Empty"
import {Row} from "react-bootstrap"
import {setActiveMenuItem} from '../../actions/NavigationActions'
import {validateInput} from '../../utils/validations/categories'
import {confirm} from '../../utils/confirm'
import {plural} from '../../utils/plural'
import {ToastDanger} from 'react-toastr-basic'
import {ToastSuccess} from 'react-toastr-basic'
import isEmpty from 'lodash/isEmpty'

import {
    getIngredientsCategories,
    addIngredientsCategory,
    updateIngredientsCategory,
    deleteIngredientsCategory
} from '../../actions/IngredientsCategoriesActions';

class SettingsPageCategories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameValue: '',
            newLabel: 'přidat',
            selectedItem: {},
            errors: [],
            counter: 0
        }
        props.actions.setActiveMenuItem('settings');
        props.actions.getIngredientsCategories();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.counter != prevState.counter) {
            const {errors} = this.state;
            Object.keys(errors).map(key =>
                ToastDanger(errors[key])
            );
        }
        if (this.props != prevProps) {
            const {errors, message} = this.props.ingredientsCategories;
            if (errors) {
                errors.map((item) =>
                    ToastDanger(item)
                );
            }
            if (message && message != prevProps.ingredientsCategories.message) {
                ToastSuccess(message);
            }
        }
    }

    isValid() {
        const {errors, isValid} = validateInput(this.state);

        if (!isValid) {
            this.setState({errors});
        }
        return isValid;
    }

    onClickEdit(item) {
        this.setState({newLabel: 'uložit', nameValue: item.name, selectedItem: item});
    }

    onClickDelete(item) {
        confirm('delete', item.name, () => this.deleteItem(item.id), () => void(0));
    }

    onClickNew(e) {
        this.setState({counter: this.state.counter + 1});
        if (this.isValid()) {
            if (isEmpty(this.state.selectedItem)) {
                this.props.actions.addIngredientsCategory({name: this.state.nameValue});
            } else {
                const data = {
                    name: this.state.nameValue,
                    id: this.state.selectedItem.id
                }
                this.props.actions.updateIngredientsCategory(data);
            }
            this.resetState();
        }
    }

    onInputChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onClickReset() {
        this.resetState();
    }

    deleteItem(id) {
        this.props.actions.deleteIngredientsCategory({id: id});
        this.resetState();
    }

    resetState() {
        this.setState({
            nameValue: '',
            newLabel: 'přidat',
            selectedItem: {},
            errors: [],
            counter: 0
        });
    }

    render() {
        const {nameValue, newLabel, selectedItem} = this.state;
        const {ingredientsCategories, search} = this.props;
        const items = search ?
            ingredientsCategories.items.filter(item => item.name.toLocaleLowerCase().includes(search.toLowerCase()))
            :
            ingredientsCategories.items;
        const boxes = items.length ? items.map(item => {
            const controls = item.editable ? [
                {
                    label: 'upravit',
                    href: '#top',
                    onClick: () => this.onClickEdit(item)
                },
                {
                    label: 'smazat',
                    onClick: () => this.onClickDelete(item)
                }
            ] : [];

            return (
                <Box
                    active={item.id === selectedItem.id}
                    key={item.id}
                    heading={item.name}
                    subheading=""
                    type="vertical"
                    warningType=""
                    controls={controls}
                    content={
                        <div className="box-content-inner">
                            <span className="number highlight">{item.cnt}</span>
                            <span className="label">{plural(item.cnt, ["surovina", "suroviny", "surovin"])} v této kategorii</span>
                        </div>
                    }
                />
            )
        }) : ingredientsCategories.isFetching ? null : <Empty/>;

        return (
            <div>
                <Navigation/>
                <div className="main-page">
                    <Header/>
                    <div className="main-content" id="top">
                        <Spinner visible={ingredientsCategories.isFetching}/>
                        <Row>
                            <BoxAddSimple
                                active={!isEmpty(selectedItem)}
                                fields={[
                                    {
                                        type: 'text',
                                        label: 'Nová kategorie',
                                        value: nameValue,
                                        name: 'nameValue',
                                        onChange: (e) => this.onInputChange(e)
                                    }
                                ]}
                                button={{label: newLabel, onClick: (e) => this.onClickNew(e)}}
                                reset={() => this.onClickReset()}
                            />
                        </Row>
                        <Row>
                            {boxes}
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

SettingsPageCategories.propTypes = {
    actions: PropTypes.object.isRequired,
}


function mapStateToProps(state) {
    return {
        ingredientsCategories: state.ingredientsCategories,
        search: state.search
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            setActiveMenuItem,
            getIngredientsCategories,
            addIngredientsCategory,
            updateIngredientsCategory,
            deleteIngredientsCategory
        }, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingsPageCategories);