//authentication
export const SET_CURRENT_USER = 'SET_CURRENT_USER'

//navigation
export const SET_ACTIVE_MENU_ITEM = 'SET_ACTIVE_MENU_ITEM'

//search
export const SET_SEARCH_STRING = 'SET_SEARCH_STRING'

//ingredients cetegories
export const REQUEST_CATEGORIES = 'REQUEST_CATEGORIES'
export const RECEIVE_CATEGORIES = 'RECEIVE_CATEGORIES'
export const ERROR_CATEGORIES = 'ERROR_CATEGORIES'

//ingredients
export const REQUEST_INGREDIENTS = 'REQUEST_INGREDIENTS'
export const RECEIVE_INGREDIENTS = 'RECEIVE_INGREDIENTS'
export const ERROR_INGREDIENTS = 'ERROR_INGREDIENTS'

//sessions
export const REQUEST_SESSIONS = 'REQUEST_SESSIONS'
export const RECEIVE_SESSIONS = 'RECEIVE_SESSIONS'
export const ERROR_SESSIONS = 'ERROR_SESSIONS'

export const SET_ACTIVE_SESSION = 'SET_ACTIVE_SESSION'

//orders
export const REQUEST_ORDERS = 'REQUEST_ORDERS'
export const RECEIVE_ORDERS = 'RECEIVE_ORDERS'
export const ERROR_ORDERS = 'ERROR_ORDERS'
export const RELOAD_ORDERS = 'RELOAD_ORDERS'

//dishes
export const REQUEST_DISHES = 'REQUEST_DISHES'
export const RECEIVE_DISHES = 'RECEIVE_DISHES'
export const ERROR_DISHES = 'ERROR_DISHES'

//units
export const REQUEST_UNITS = 'REQUEST_UNITS'
export const RECEIVE_UNITS = 'RECEIVE_UNITS'
export const ERROR_UNITS = 'ERROR_UNITS'

//unitCategories
export const REQUEST_UNIT_CATEGORIES = 'REQUEST_UNIT_CATEGORIES'
export const RECEIVE_UNIT_CATEGORIES = 'RECEIVE_UNIT_CATEGORIES'
export const ERROR_UNIT_CATEGORIES = 'ERROR_UNIT_CATEGORIES'

//recipes
export const REQUEST_RECIPES = 'REQUEST_RECIPES'
export const RECEIVE_RECIPES = 'RECEIVE_RECIPES'
export const ERROR_RECIPES = 'ERROR_RECIPES'
export const RELOAD_RECIPES = 'RELOAD_RECIPES'

//warehouse
export const REQUEST_WAREHOUSE = 'REQUEST_WAREHOUSE'
export const RECEIVE_WAREHOUSE = 'RECEIVE_WAREHOUSE'
export const ERROR_WAREHOUSE = 'ERROR_WAREHOUSE'
export const RELOAD_WAREHOUSE = 'RELOAD_WAREHOUSE'

//purchase
export const REQUEST_PURCHASE = 'REQUEST_PURCHASE'
export const RECEIVE_PURCHASE = 'RECEIVE_PURCHASE'
export const RECEIVE_LAST_PURCHASE = 'RECEIVE_LAST_PURCHASE'
export const ERROR_PURCHASE = 'ERROR_PURCHASE'

//warehouse status
export const REQUEST_WAREHOUSE_STATUS = 'REQUEST_WAREHOUSE_STATUS'
export const RECEIVE_WAREHOUSE_STATUS = 'RECEIVE_WAREHOUSE_STATUS'
export const ERROR_WAREHOUSE_STATUS = 'ERROR_WAREHOUSE_STATUS'
export const RELOAD_WAREHOUSE_STATUS = 'RELOAD_WAREHOUSE_STATUS'

//log
export const REQUEST_LOG = 'REQUEST_LOG'
export const RECEIVE_LOG = 'RECEIVE_LOG'
export const ERROR_LOG = 'ERROR_LOG'

