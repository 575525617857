import React from 'react'
import classnames from 'classnames'

class Spinner extends React.Component {
    render() {
        return (
            <div className={classnames("spinner", this.props.visible ? "active" : "")}>
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
            </div>
        );
    }
}

export default Spinner
