import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import PropTypes from 'prop-types'
import Header from "../common/Header"
import Navigation from "../common/Navigation"
import Box from "../common/Box"
import BoxAddSimple from "../common/BoxAddSimple"
import Spinner from "../common/Spinner"
import Empty from "../common/Empty"
import {Row, Col} from "react-bootstrap"
import {setActiveMenuItem} from '../../actions/NavigationActions'
import {validateInput} from '../../utils/validations/ingredients'
import {ToastDanger} from 'react-toastr-basic'
import {ToastSuccess} from 'react-toastr-basic'
import isEmpty from 'lodash/isEmpty'
import {MobileView} from "react-device-detect"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Scanner from '../common/Scanner'

import {
    getIngredients,
    addIngredient,
    updateIngredient,
} from '../../actions/IngredientsActions';

import {
    getIngredientsCategories
} from '../../actions/IngredientsCategoriesActions';

import {
    getUnitCategories
} from '../../actions/UnitCategoriesActions';

class SettingsPageSessions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameValue: '',
            categoryValue: '',
            unitCategoryValue: '',
            barcodeValue: '',
            newLabel: 'přidat',
            selectedItem: {},
            errors: [],
            counter: 0,
            showScanner: false
        }
        props.actions.setActiveMenuItem('settingsIngredients');
        props.actions.getIngredients();
        props.actions.getIngredientsCategories();
        props.actions.getUnitCategories();
    }

    handleBarcodeClick() {
        this.setState({showScanner: true});
    }

    onDetected(result) {
        const items = this.props.ingredients.items;
        let flag = true;
        for (var i = 0; i < items.length; i++){
            for(var j = 0; j < items[i].barcodes.length; j++){
                if(items[i].barcodes[j].barcode == result){
                    this.setState({errors : {barcode: "Čárový kód je již definován pro surovinu: " + items[i].label}, counter: this.state.counter++});
                    flag = false
                    break;
                }
            }
            if(!flag) break;
        }
        if(flag){
            this.setState({barcodeValue: result, errors: []});
        }
    }

    onCloseScanner() {
        this.setState({showScanner: false});
    }

    onDeleteBarcode(index){
        let barcodes = this.state.barcodeValue.slice(0);
        barcodes.splice(index, 1);
        this.setState({barcodeValue: barcodes});
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.counter != prevState.counter) {
            const {errors} = this.state;
            Object.keys(errors).map(key =>
                ToastDanger(errors[key])
            );
        }
        if (this.props != prevProps) {
            const {errors, message} = this.props.ingredients;
            if (errors) {
                errors.map((item) =>
                    ToastDanger(item)
                );
            }
            if (message && message != prevProps.ingredients.message) {
                ToastSuccess(message);
            }
        }
    }

    isValid() {
        const {errors, isValid} = validateInput(this.state);

        if (!isValid) {
            this.setState({errors});
        }
        return isValid;
    }

    onClickEdit(item) {
        const categoryValue = {
            value: item.id_category,
            label: item.category
        }
        const unitCategoryValue = {
            value: item.id_unit_category,
            label: item.id_unit_category ? this.props.unitCategories.items[item.id_unit_category - 1].label : 'Fyz. veličina'
        }

        this.setState({
            newLabel: 'uložit',
            nameValue: item.label,
            categoryValue: categoryValue,
            unitCategoryValue: unitCategoryValue,
            barcodeValue: item.barcodes.length ? item.barcodes : '',
            selectedItem: item
        });
    }


    onClickNew(e) {
        this.setState({counter: this.state.counter + 1});
        if (this.isValid()) {
            if (isEmpty(this.state.selectedItem)) {
                this.props.actions.addIngredient({
                    name: this.state.nameValue,
                    category: this.state.categoryValue.value,
                    unitCategory: this.state.unitCategoryValue.value,
                    barcode: this.state.barcodeValue
                });
            } else {
                let barcode = null;
                if(Array.isArray(this.state.barcodeValue)){
                    barcode = this.state.barcodeValue.map((item) => item.id)
                }else{
                    barcode = this.state.barcodeValue
                }
                this.props.actions.updateIngredient({
                    name: this.state.nameValue,
                    category: this.state.categoryValue.value,
                    id: this.state.selectedItem.value,
                    barcode: barcode
                });
            }
            this.resetState();
        }
    }

    onInputChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onSelectCategory(e) {
        this.setState({categoryValue: e});
    }

    onSelectUnitCategory(e) {
        this.setState({unitCategoryValue: e});
    }

    onClickReset() {
        this.resetState();
    }

    onBarcodeRemove() {
        this.setState({barcodeValue: ''});
    }

    resetState() {
        this.setState({
            nameValue: '',
            categoryValue: '',
            unitCategoryValue: '',
            barcodeValue: '',
            newLabel: 'přidat',
            selectedItem: {},
            errors: [],
            counter: 0,
            showScanner: false
        });
    }

    render() {
        const {nameValue, categoryValue, unitCategoryValue, barcodeValue, newLabel, selectedItem, showScanner} = this.state;
        const {ingredients, search, ingredientsCategories, unitCategories} = this.props;
        const items = search ?
            ingredients.items.filter(item => item.label.toLocaleLowerCase().includes(search.toLowerCase()))
            :
            ingredients.items;

        const selectCategories = ingredientsCategories.items.map((item) => {
            return {
                value: item.id,
                label: item.name
            }
        });

        const boxes = items.length ? items.map(item => {
            const controls = [
                {
                    label: 'upravit',
                    href: '#top',
                    onClick: () => this.onClickEdit(item)
                }
            ]

            return (
                <Box
                    active={item.value === selectedItem.value}
                    key={item.value}
                    heading={item.label}
                    subheading={item.category}
                    type="vertical"
                    warningType=""
                    controls={controls}
                />
            )
        }) : ingredients.isFetching ? null : <Empty/>;

        return (
            <div>
                <Navigation/>
                <div className="main-page">
                    <Header/>
                    <div className="main-content" id="top">
                        <Spinner visible={ingredients.isFetching}/>
                                <Row>
                                    <Col sm={12}>
                                        <MobileView viewClassName="barcode">
                                            <div onClick={() => this.handleBarcodeClick()}>
                                                <FontAwesomeIcon icon="barcode" size="3x"/>
                                            </div>
                                            <div>{barcodeValue ? "Načíst nový kód" : "Načíst kód"}</div>
                                            {barcodeValue ?
                                                <div className="barcode-remove" onClick={() => this.onBarcodeRemove()}>
                                                    <FontAwesomeIcon icon="times" size="2x"/></div> : null}
                                            {showScanner ? <Scanner onDetected={(result) => this.onDetected(result)}
                                                                    onClose={() => this.onCloseScanner()}/> : null}
                                        </MobileView>
                                    </Col>
                                </Row>
                        <Row>
                            <BoxAddSimple
                                active={!isEmpty(selectedItem)}
                                fields={[
                                    {
                                        type: 'text',
                                        label: 'Název suroviny',
                                        value: nameValue,
                                        name: 'nameValue',
                                        onChange: (e) => this.onInputChange(e)
                                    },
                                    {
                                        type: 'select',
                                        label: 'Kategorie',
                                        value: categoryValue,
                                        name: 'categoryValue',
                                        data: selectCategories,
                                        onChange: (e) => this.onSelectCategory(e)
                                    },
                                    {
                                        type: 'barcode',
                                        label: 'Čárový kód',
                                        value: barcodeValue,
                                        name: 'barcodeValue',
                                        data: barcodeValue,
                                        onChange: (e) => this.onInputChange(e),
                                        onDelete: (e) => this.onDeleteBarcode(e)
                                    },
                                    {
                                        type: 'select',
                                        label: 'Fyz. veličina',
                                        value: unitCategoryValue,
                                        name: 'unitCategoryValue',
                                        disabled: !isEmpty(selectedItem),
                                        data: unitCategories.items,
                                        onChange: (e) => this.onSelectUnitCategory(e)
                                    }
                                ]}
                                button={{label: newLabel, onClick: (e) => this.onClickNew(e)}}
                                reset={() => this.onClickReset()}
                            />
                        </Row>
                        <Row>
                            {boxes}
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

SettingsPageSessions.propTypes = {
    actions: PropTypes.object.isRequired,
}


function mapStateToProps(state) {
    return {
        ingredients: state.ingredients,
        ingredientsCategories: state.ingredientsCategories,
        unitCategories: state.unitCategories,
        search: state.search
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            setActiveMenuItem,
            addIngredient,
            updateIngredient,
            getIngredients,
            getIngredientsCategories,
            getUnitCategories,
        }, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingsPageSessions);