import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {Redirect} from "react-router-dom";

export default function(ComposedComponent) {

    class Authenticate extends React.Component {
        componentWillMount() {
            if (!this.props.isAuthenticated) {
                //this.context.router.history.push('/');
            }
        }

        componentWillUpdate(nextProps) {
            if (!nextProps.isAuthenticated) {
                //this.context.router.history.push('/');
            }
        }

        render() {
            if(!this.props.isAuthenticated){
                return <Redirect to={"/"}/>
            }
            return (
                <ComposedComponent {...this.props} />
            );
        }
    }

    Authenticate.propTypes = {
        isAuthenticated: PropTypes.bool.isRequired
    }

    Authenticate.contextTypes = {
        router: PropTypes.object.isRequired
    }

    function mapStateToProps(state) {
        return {
            isAuthenticated: state.auth.isAuthenticated
        };
    }

    return connect(
        mapStateToProps,
        null
    )(Authenticate);
}