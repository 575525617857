import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import Subnavigation from "../common/Subnavigation"
import Search from "../common/Search"
import SessionInfo from "../common/SessionInfo"
import {navigation} from '../../constants/constants'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

class Header extends React.Component {
    render() {
        let slash = '';
        const heading = navigation[this.props.navigation].headings.map((item, i) => {
                let h = slash + item;
                slash = ' / ';
                return (
                    <span key={i}>{h}</span>
                )
            }
        );
        const icon = navigation[this.props.navigation].navigation.icon2 || navigation[this.props.navigation].navigation.icon;
        return (
            <div className="header">
                <h1><FontAwesomeIcon icon={icon} size="2x"/>{heading}</h1>
                <Search/>
                <Subnavigation/>
                <SessionInfo/>
            </div>
        );
    }
}

Header.propTypes = {
    navigation: PropTypes.string.isRequired
}

function mapStateToProps(state) {
    return {
        navigation: state.navigation
    };
}

export default connect(
    mapStateToProps,
    null
)(Header);