//authentiation
export const user = {
    isAuthenticated: false,
    user: {}
};

//navigation
export const activeMenuItem = 'orders';

//search
export const searchString = '';

//Fetch state
export const initialFetchState = {
    items: [],
    isFetching: false,
    shouldReload: false,
    errors: [],
    message: ''
}

//Fetch purchase
export const initialFetchPurchaseState = {
    purchaseDetail: {},
    lastPurchase: {},
    isFetching: false,
    errors: [],
    message: ''
}

//session
export const activeSession = {};
