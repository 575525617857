import { SET_CURRENT_USER } from '../constants/types'
import isEmpty from 'lodash/isEmpty'
import { user } from '../constants/initialState'


export default (state = user, action = {}) => {
    switch(action.type) {
        case SET_CURRENT_USER:
            return {
                isAuthenticated: !isEmpty(action.user),
                user: action.user
            };
        default: return state;
    }
}