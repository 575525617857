import isEmpty from 'lodash/isEmpty'
import isInteger from 'lodash/isInteger'
import isNumber from 'lodash/isNumber'

export function validateInput(data) {
    let errors = {};
    if (isEmpty(data.name.name)) {
        errors.name = 'Nesprávná hodnota pole Jídlo';
    }

    if (isEmpty(data.name.name) && isEmpty(data.name.id)) {
        errors.name = 'Nesprávná hodnota pole Jídlo';
    }
    let _ingredients = data.ingredients.slice(0);
    _ingredients.pop();
    if (isEmpty(_ingredients)) {
        errors.ingredients = 'Recept musí obsahovat alespoň jednu položku';
    }else{
        for(var i = 0; i < _ingredients.length; i++){
            var item = _ingredients[i];
            if (isEmpty(item.ingredient.label)) {
                errors.ingredients = 'Nesprávná hodnota pole Surovina';
            }

            if (isNaN(parseFloat(item.quantity)) || item.quantity < 0) {
                errors.ingredients = 'Nesprávná hodnota pole Množství u položky ' + item.ingredient.label;
            }

            if (!isInteger(item.unit)) {
                errors.ingredients = 'Nesprávná hodnota pole Jednotka u položky ' + item.ingredient.label;
            }
            break;
        }
    }

    return {
        errors,
        isValid: isEmpty(errors)
    };
}

export function validateIngredient(data){
    let errors = {};
    if (isEmpty(data.ingredient.label)) {
        errors.name = 'Nesprávná hodnota pole Surovina';
    }

    if (isNaN(parseFloat(data.quantity)) || data.quantity < 0) {
        errors.quantity = 'Nesprávná hodnota pole Množství';
    }

    if (!isInteger(data.unit) || data.unit < 0) {
        errors.unit = 'Nebyla vybrána jednotka';
    }

    return {
        errors,
        isValid: isEmpty(errors)
    };
}