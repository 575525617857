import axios from 'axios'
import setAuthToken from '../utils/setAuthToken'
import jwtDecode from 'jwt-decode'
import {SET_CURRENT_USER} from '../constants/types'
import  {fetchUrl} from '../constants/constants'

import {getSessions} from './SessionsActions'

export function setCurrentUser(user) {
    return {
        type: SET_CURRENT_USER,
        user
    };
}

export function logout() {
    return dispatch => {
        localStorage.removeItem('jwtToken');
        setAuthToken(false);
        dispatch(setCurrentUser({}));
    }
}

export function login(data) {
    return dispatch => {
        return axios.post(fetchUrl + 'login', data).then(res => {
            if (res.data.data.success) {
                const token = res.data.data.token;
                if (token) {
                    localStorage.setItem('jwtToken', token);
                    setAuthToken(token);
                    dispatch(setCurrentUser(jwtDecode(token)));
                    dispatch(getSessions());
                    return true;
                }
            } else {
                return res.data.message;
            }
        }).catch(err => {
            return "Neočekávaná chyba";
        });
    }
}

export function sendResetEmail(data) {
    return dispatch => {
        return axios.post(fetchUrl + 'sendResetEmail', data).then(res => {
            if (res.data.data.success) {
                return true;
            } else {
                return res.data.message;
            }
        }).catch(err => {
            return "Neočekávaná chyba";
        });
    }
}

export function resetPassword(data) {
    return dispatch => {
        return axios.post(fetchUrl + 'resetPassword', data).then(res => {
            if (res.data.data.success) {
                return true;
            } else {
                return res.data.message;
            }
        }).catch(err => {
            return "Neočekávaná chyba";
        });
    }
}

export function changePassword(data) {
    return dispatch => {
        return axios.post(fetchUrl + 'changePassword', data).then(res => {
            if (res.data.data.success) {
                return true;
            } else {
                return res.data.message;
            }
        }).catch(err => {
            return "Neočekávaná chyba";
        });
    }
}

export function validateLink(data) {
    return dispatch => {
        return axios.post(fetchUrl + 'validateLink', data).then(res => {
            if (res.data.data.success) {
                return true;
            } else {
                return false;
            }
        }).catch(err => {
            return false;
        });
    }
}

export function editUser(data) {
    return dispatch => {
        return axios.post(fetchUrl + 'editUser', data).then(res => {
            if (res.data.data.success) {
                const token = res.data.data.token;
                if (token) {
                    localStorage.setItem('jwtToken', token);
                    setAuthToken(token);
                    dispatch(setCurrentUser(jwtDecode(token)));
                    return true;
                }
            } else {
                return res.data.message;
            }
        }).catch(err => {
            return "Neočekávaná chyba";
        });
    }
}