import validator from 'validator'
import isEmpty from 'lodash/isEmpty'

export function validateInput(data) {
    let errors = {};

    if (!validator.isEmail(data.identifier)) {
        errors.identifier = 'Neplatný formát emailu';
    }
    if (validator.isEmpty(data.identifier)) {
        errors.identifier = 'Vyplňte email';
    }
    if (validator.isEmpty(data.password)) {
        errors.password = 'Vyplňte heslo';
    }

    return {
        errors,
        isValid: isEmpty(errors)
    };
}

export function validateEmail(data) {
    let errors = {};

    if (!validator.isEmail(data.email)) {
        errors.email = 'Neplatný formát emailu';
    }
    if (validator.isEmpty(data.email)) {
        errors.email = 'Vyplňte email';
    }

    return {
        errors,
        isValid: isEmpty(errors)
    };
}

export function validatePassword(data, changePass) {
    let changePass_ = changePass == null ? false : true;
    let errors = {};

    if(changePass_){
        if (validator.isEmpty(data.passwordOld)) {
            errors.passwordOld = 'Vyplňte současné heslo';
        }
    }

    if (data.password !== data.passwordCheck) {
        errors.password = 'Hesla se neshodují';
    }

    if (data.password.length < 8) {
        errors.password = 'Minimální délka hesla je 8 znaků';
    }

    if (validator.isEmpty(data.password)) {
        errors.password = 'Vyplňte heslo';
    }

    if (validator.isEmpty(data.passwordCheck)) {
        errors.passwordCheck = 'Vyplňte potvrzení hesla';
    }



    return {
        errors,
        isValid: isEmpty(errors)
    };
}

export function validateProfileInput(data) {
    let errors = {};

    if (validator.isEmpty(data.name)) {
        errors.name = 'Vyplňte jméno';
    }
    if (!validator.isEmail(data.identifier)) {
        errors.identifier = 'Neplatný formát emailu';
    }
    if (validator.isEmpty(data.identifier)) {
        errors.identifier = 'Vyplňte email';
    }
    if (validator.isEmpty(data.password)) {
        errors.password = 'Vyplňte heslo';
    }

    return {
        errors,
        isValid: isEmpty(errors)
    };
}