import {
    REQUEST_UNITS,
    RECEIVE_UNITS,
    ERROR_UNITS
} from '../constants/types'
import { initialFetchState } from '../constants/initialState'


export default (state = initialFetchState, action = {}) => {
    switch(action.type) {
        case REQUEST_UNITS:
            return Object.assign({}, state, {
                isFetching: true,
                message: '',
                errors: []
            })
        case RECEIVE_UNITS:
            return Object.assign({}, state, {
                isFetching: false,
                items: action.units,
                message: action.message
            })
        case ERROR_UNITS:
            return Object.assign({}, state, {
                isFetching: false,
                errors: action.errors
            })
        default: return state;
    }
}