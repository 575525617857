import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import PropTypes from 'prop-types'
import Header from "../common/Header"
import Navigation from "../common/Navigation"
import Box from "../common/Box"
import BoxAddPurchase from "../common/BoxAddPurchase"
import DisplayPurchaseDetail from "../common/DisplayPurchaseDetail"
import Spinner from "../common/Spinner"
import Empty from "../common/Empty"
import {Row, Col} from "react-bootstrap"
import {setActiveMenuItem} from '../../actions/NavigationActions'
import {validateInput} from '../../utils/validations/purchase'
import {ToastDanger} from 'react-toastr-basic'
import {ToastSuccess} from 'react-toastr-basic'
import {MobileView} from "react-device-detect"
import Scanner from '../common/Scanner'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import isEmpty from 'lodash/isEmpty'
import classnames from 'classnames'
import {formatDate} from 'react-day-picker/moment'

import {
    getIngredients
} from '../../actions/IngredientsActions';
import {getUnits} from '../../actions/UnitsActions'
import {getIngredientsCategories} from '../../actions/IngredientsCategoriesActions'
import {
    getPurchaseDetail,
    getLastPurchase,
    addPurchase,
    updatePurchase
} from '../../actions/PurchaseActions'
import {getWarehouse} from '../../actions/WarehouseActions'


class WarehousePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newItem: {
                nameValue: '',
                priceValue: '',
                quantityValue: '',
                unitValue: '',
                wasteValue: '',
                barcodeValue: '',
                expirationValue: {nice: '', ugly: ''}
            },
            displayItem: {},
            filter: {
                category: [],
                status: ''
            },
            editingItem: false,
            errors: [],
            counter: 0,
            showScanner: false
        }
        props.actions.setActiveMenuItem('warehouse');
        props.actions.getWarehouse();
        props.actions.getIngredients();
        props.actions.getIngredientsCategories();
        props.actions.getUnits();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.counter != prevState.counter) {
            const {errors} = this.state;
            Object.keys(errors).map(key =>
                ToastDanger(errors[key])
            );
        }
        if (this.props != prevProps) {
            const {errors, message} = this.props.purchaseDetail;
            if (errors) {
                errors.map((item) =>
                    ToastDanger(item)
                );
            }
            if (message && message != prevProps.purchaseDetail.message) {
                ToastSuccess(message);
            }
            const purchaseDetail = this.props.purchaseDetail;
            if(!isEmpty(purchaseDetail.lastPurchase) && !purchaseDetail.isFetching){
                const last = purchaseDetail.lastPurchase;
                if(!isEmpty(last)){
                    let newItem = {
                        nameValue: this.state.newItem.nameValue,
                        priceValue: last.price,
                        quantityValue: last.quantity,
                        unitValue: last.id_unit,
                        wasteValue: last.waste,
                        expirationValue: {nice: last.expiration ? formatDate(last.expiration, 'YYYY-MM-DD') : '', ugly: last.expiration || ''}
                    }
                    this.setState({newItem: newItem}, () => {
                        ToastSuccess("Načtena data z posledního nákupu");
                    });
                }
            }
        }
    }

    isValid() {
        const {errors, isValid} = validateInput(this.state);

        if (!isValid) {
            this.setState({errors});
        }
        return isValid;
    }

    handleBarcodeClick() {
        this.setState({showScanner: true});
    }

    onDetected(result) {

        let newItem = this.state.newItem;
        newItem.barcodeValue = result;

        const items = this.props.ingredients.items;
        let found = false;
        for (var i = 0; i < items.length; i++) {
            for (var j = 0; j < items[i].barcodes.length; j++) {
                if (items[i].barcodes[j].barcode == result) {
                    newItem.nameValue = {
                        value: items[i].value,
                        label: items[i].label,
                        id_unit_category: items[i].id_unit_category
                    };
                    newItem.quantityValue = items[i].barcodes[j].quantity ? items[i].barcodes[j].quantity : '';
                    newItem.unitValue = items[i].barcodes[j].id_unit_category ? items[i].barcodes[j].id_unit_category : '';
                    found = true;
                    break;
                }
            }
            if (found) break;
        }
        this.setState({newItem: newItem});
    }

    onCloseScanner() {
        this.setState({showScanner: false});
    }

    onClickCloseDisplay() {
        this.setState({displayItem: {}});
    }

    onClickDetail(item) {
        this.props.actions.getPurchaseDetail({id_ingredient: item.value});
        this.setState({displayItem: {label: item.label, category: item.category}});
    }

    onClickNew(e) {
        this.setState({counter: this.state.counter + 1});
        if (this.isValid()) {
            const {newItem} = this.state;
            if (this.state.editingItem) {
                this.props.actions.updatePurchase({
                    id: this.state.editingItem,
                    quantity: newItem.quantityValue,
                    price: newItem.priceValue,
                    waste: newItem.wasteValue || 0,
                    unit: newItem.unitValue,
                    expiration: newItem.expirationValue.ugly
                });
            } else {
                this.props.actions.addPurchase({
                    ingredient: newItem.nameValue,
                    quantity: newItem.quantityValue,
                    price: newItem.priceValue,
                    waste: newItem.wasteValue || 0,
                    unit: newItem.unitValue,
                    barcode: newItem.barcodeValue,
                    expiration: newItem.expirationValue.ugly
                });
            }
            this.resetState(this.state.displayItem);
        }
    }

    onClickAdd(item) {
        this.getLastPurchase(item);
    }

    getLastPurchase(item){
        this.props.actions.getLastPurchase({id_ingredient: item.value});
        let newItem = {
            nameValue: item,
            priceValue: '',
            quantityValue: '',
            unitValue: '',
            wasteValue: '',
            expirationValue: {nice: '', ugly: ''}
        }
        this.setState({newItem: newItem, displayItem: {}});
    }

    onIngredientCreate(label) {
        let newItem = this.state.newItem;
        newItem.nameValue = {label: label, value: ''};
        this.setState({newItem: newItem});
    }

    onSelectIngredient(e) {
        if(!isEmpty(e)){
            this.getLastPurchase(e);
        }
    }

    onSelectUnit(unit) {
        let newItem = this.state.newItem;
        newItem.unitValue = unit;
        this.setState({newItem: newItem});
    }

    onChangeIngredient(e) { //inputs
        let value = parseFloat(e.target.value);
        if (isNaN(value)) {
            value = '';
        }
        let newItem = this.state.newItem;
        newItem[e.target.name] = value;
        this.setState({newItem: newItem});
    }

    onSelectDate(ugly, nice) { //inputs
        let newItem = this.state.newItem;
        newItem.expirationValue = {nice: nice, ugly: ugly};
        this.setState({newItem: newItem});
    }

    onClickReset() {
        this.resetState();
    }

    onSelectCategoryFilter(id) {
        let currentFilter = this.state.filter;
        const index = currentFilter.category.findIndex(i => i == id);
        if (index != -1) {
            currentFilter.category.splice(index, 1);
        } else {
            currentFilter.category.push(id);
        }

        this.setState({filter: currentFilter});
    }

    onSelectStatusFilter(filter) {
        let currentFilter = this.state.filter;
        if (currentFilter.status == filter) {
            currentFilter.status = '';
        } else {
            currentFilter.status = filter;
        }
        this.setState({filter: currentFilter});
    }

    onClickUpdate(item) {
        let exp = item.expiration ? item.expiration : '';
        let newItem = {
            nameValue: {
                value: -1,
                label: item.ingredient,
                id_unit_category: item.id_unit_category
            },
            priceValue: item.price,
            quantityValue: item.quantity,
            unitValue: item.id_unit,
            wasteValue: item.waste,
            expirationValue: {nice: exp, ugly: exp}
        }
        this.setState({newItem: newItem, editingItem: item.id});
    }

    onBarcodeRemove() {
        let newItem = this.state.newItem;
        newItem.barcodeValue = '';
        this.setState({newItem: newItem});
    }

    resetState(display = {}) {
        this.setState({
            newItem: {
                nameValue: '',
                priceValue: '',
                quantityValue: '',
                unitValue: '',
                wasteValue: '',
                barcodeValue: '',
                expirationValue: {nice: '', ugly: ''}
            },
            displayItem: display,
            filter: {
                category: [],
                status: ''
            },
            editingItem: false,
            errors: [],
            counter: 0,
            showScanner: false
        });
    }

    render() {
        const {newItem, displayItem, filter, editingItem, showScanner} = this.state;
        const {warehouse, ingredients, search, ingredientsCategories} = this.props;

        let items = !isEmpty(filter.category) ? warehouse.items.filter(item => filter.category.includes(item.id_category))
            :
            warehouse.items;

        items = filter.status ? items.filter(item => item.expired == 1)
            :
            items;

        items = search ?
            items.filter(item => item.label.toLocaleLowerCase().includes(search.toLowerCase()))
            :
            items;

        const boxes = items.length ? items.map(item => {
            const controls = [
                {
                    label: 'přidat',
                    href: '#top',
                    onClick: () => this.onClickAdd(item)
                },
                {
                    label: 'detail',
                    href: '#top',
                    onClick: () => this.onClickDetail(item)
                }
            ]

            return (
                <Box
                    active={displayItem.label == item.label}
                    key={item.value}
                    heading={item.label}
                    subheading={item.category}
                    type="vertical"
                    warningType={item.expired ? 'danger' : ''}
                    controls={controls}
                />
            )
        }) : ingredients.isFetching ? null : <Empty/>;

        const filterButtons = ingredientsCategories.items.map((item, index) =>
            <button
                key={index}
                type="button"
                className={classnames("btn btn-theme", filter.category.includes(item.id) ? "active" : "")}
                role="radio"
                name="filter"
                onClick={(e) => this.onSelectCategoryFilter(item.id)}
            >{item.name}
            </button>
        )

        const filterButtons2 = <button
            key='button_status'
            type="button"
            title="surovina expiruje dnes nebo již expirovala"
            className={classnames("btn btn-theme", filter.status == 'danger' ? "active" : "")}
            role="radio"
            name="filter"
            onClick={(e) => this.onSelectStatusFilter('danger')}
        ><FontAwesomeIcon icon="exclamation-circle"/> expirace
        </button>

        return (
            <div>
                <Navigation/>
                <div className="main-page">
                    <Header/>
                    <div className="main-content" id="top">
                        <Spinner visible={warehouse.isFetching}/>
                        <Row className="controls-row">
                            <Col xs={12}>
                                <div className="controls-row-wrapper">
                                    <div className="filters-wrapper filter-categories">
                                        <div><span>Filtr: </span></div>
                                        <div>
                                            {filterButtons}
                                            {filterButtons2}
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <MobileView viewClassName="barcode">
                                    <div onClick={() => this.handleBarcodeClick()}>
                                        <FontAwesomeIcon icon="barcode" size="3x"/>
                                    </div>
                                    <div>{newItem.barcodeValue ? "Načíst nový kód" : "Načíst kód"}</div>
                                    {newItem.barcodeValue ?
                                        <div className="barcode-remove" onClick={() => this.onBarcodeRemove()}>
                                            <FontAwesomeIcon icon="times" size="2x"/></div> : null}
                                    {showScanner ? <Scanner onDetected={(result) => this.onDetected(result)}
                                                            onClose={() => this.onCloseScanner()}/> : null}
                                </MobileView>
                            </Col>
                        </Row>
                        <Row>
                            <BoxAddPurchase
                                active={newItem.nameValue || newItem.unitValue || newItem.quantityValue || newItem.priceValue || newItem.wasteValue || newItem.barcodeValue}
                                item={newItem}
                                units={this.props.units.isFetching ? [] : this.props.units.items}
                                ingredients={this.props.ingredients.isFetching ? [] : this.props.ingredients.items}
                                button={{
                                    label: editingItem ? 'uložit' : 'přidat',
                                    onClick: (e) => this.onClickNew(e)
                                }}
                                reset={() => this.onClickReset()}
                                onSelectIngredient={(e) => this.onSelectIngredient(e)}
                                onChangeIngredient={(e) => this.onChangeIngredient(e)}
                                onSelectUnit={(unit) => this.onSelectUnit(unit)}
                                onIngredientCreate={(e) => this.onIngredientCreate(e)}
                                onSelectDate={(ugly, nice) => this.onSelectDate(ugly, nice)}
                            />
                        </Row>
                        {
                            !isEmpty(displayItem) ?
                                <DisplayPurchaseDetail
                                    item={displayItem}
                                    data={this.props.purchaseDetail.isFetching ? {} : this.props.purchaseDetail.purchaseDetail}
                                    isFetching={this.props.purchaseDetail.isFetching}
                                    onClose={() => this.onClickCloseDisplay()}
                                    onUpdate={(item) => this.onClickUpdate(item)}
                                />
                                :
                                null

                        }
                        <Row>
                            {boxes}
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

WarehousePage.propTypes = {
    actions: PropTypes.object.isRequired,
}


function mapStateToProps(state) {
    return {
        ingredients: state.ingredients,
        purchaseDetail: state.purchaseDetail,
        warehouse: state.warehouse,
        units: state.units,
        ingredientsCategories: state.ingredientsCategories,
        search: state.search
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            setActiveMenuItem,
            getIngredients,
            getUnits,
            getPurchaseDetail,
            getLastPurchase,
            addPurchase,
            updatePurchase,
            getWarehouse,
            getIngredientsCategories
        }, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WarehousePage);