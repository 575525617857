import axios from 'axios'
import {
    REQUEST_LOG,
    RECEIVE_LOG,
    ERROR_LOG
} from '../constants/types'
import  {fetchUrl} from '../constants/constants'

function request() {
    return {
        type: REQUEST_LOG
    };
}

function receive(data, message = '') {
    return {
        type: RECEIVE_LOG,
        log: data,
        message: message
    }
}

function failed(data) {
    return {
        type: ERROR_LOG,
        errors: data
    }
}

function shouldFetch(state, basedOnData = false) {
    const fetch = state.log;
    if (!fetch) {
        return true
    } else if (fetch.isFetching) {
        return false
    } else if (basedOnData) {
        if(fetch.items.length == 0){
            return true
        }else{
            return false
        }
    } else {
        return true
    }
}

function doFetch(data, req) {
    return dispatch => {
        dispatch(request());
        return axios.post(fetchUrl + req, data).then(res => {
            const response = res.data;
                if (response.data.success) {
                    dispatch(receive(response.data.data, response.message));
                } else {
                    dispatch(failed([response.message]))
                }
            }).catch(err => {
                return dispatch(failed(["Neočekávaná chyba"]))
            })
    }
}

function fetch(type, data = {}) {
    let request;
    let compareData;
    switch (type) {
        case 'get':
            request = 'getLog';
            compareData = false;
            break;
        default:
            request = 'getLog';
            compareData = false;
    }
    return (dispatch, getState) => {
        if (shouldFetch(getState(), compareData)) {
            return dispatch(doFetch(data, request))
        } else {
            return Promise.resolve()
        }
    }
}

export function getLog(data = {}){
    return fetch('get', data);
}