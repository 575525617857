import axios from 'axios'
import {
    REQUEST_WAREHOUSE,
    RECEIVE_WAREHOUSE,
    ERROR_WAREHOUSE,
    RELOAD_WAREHOUSE
} from '../constants/types'
import  {fetchUrl} from '../constants/constants'
import isEmpty from 'lodash/isEmpty'

function requestWarehouse() {
    return {
        type: REQUEST_WAREHOUSE
    };
}

function receiveWarehouse(data, message = '') {
    return {
        type: RECEIVE_WAREHOUSE,
        data: data,
        message: message
    }
}

function failedWarehouse(data) {
    return {
        type: ERROR_WAREHOUSE,
        errors: data
    }
}

export function reloadWarehouse(){
    return {
        type: RELOAD_WAREHOUSE
    }
}

function shouldFetchWarehouse(state, basedOnData = false) {
    const fetch = state.warehouse;
    if (!fetch) {
        return true
    } else if (fetch.isFetching) {
        return false
    } else if (fetch.shouldReload) {
        return true
    } else if (basedOnData) {
        if(isEmpty(fetch.items)){
            return true
        }else{
            return false
        }
    } else {
        return true
    }
}

function doFetchWarehouse(data, request) {
    return dispatch => {
        dispatch(requestWarehouse());
        return axios.post(fetchUrl + request, data).then(res => {
            const response = res.data;
                if (response.data.success) {
                    dispatch(receiveWarehouse(response.data.data, response.message));
                } else {
                    dispatch(failedWarehouse([response.message]))
                }
            }).catch(err => {
                return dispatch(failedWarehouse(["Neočekávaná chyba"]))
            })
    }
}

function fetchWarehouse(type, data = {}, forceFetch = false) {
    let request;
    let compareData;
    switch (type) {
        case 'get':
            request = 'getWarehouse';
            compareData = !forceFetch;
            break;
        default:
            request = 'getWarehouse';
            compareData = !forceFetch;
    }
    return (dispatch, getState) => {
        if (shouldFetchWarehouse(getState(), compareData)) {
            return dispatch(doFetchWarehouse(data, request))
        } else {
            return Promise.resolve()
        }
    }
}

export function getWarehouse(data = {}, forceFetch = false){
    return fetchWarehouse('get', data, forceFetch);
}