import {
    REQUEST_SESSIONS,
    RECEIVE_SESSIONS,
    ERROR_SESSIONS,
    SET_ACTIVE_SESSION
} from '../constants/types'
import {initialFetchState, activeSession} from '../constants/initialState'
import isArray from 'lodash/isArray'


export function fetchReducer(state = initialFetchState, action = {}) {
    switch (action.type) {
        case REQUEST_SESSIONS:
            return Object.assign({}, state, {
                isFetching: true,
                message: '',
                errors: []
            })
        case RECEIVE_SESSIONS:
            return Object.assign({}, state, {
                isFetching: false,
                items: action.sessions,
                message: action.message,
            })
        case ERROR_SESSIONS:
            return Object.assign({}, state, {
                isFetching: false,
                errors: action.errors
            })
        default:
            return state;
    }
}

export function activeSessionReducer(state = activeSession, action = {}) {
    switch (action.type) {
        case SET_ACTIVE_SESSION:
            return action.sessions
        default:
            return state;
    }
}