export const homepage = "/objednavky"

export const fetchUrl = process.env.NODE_ENV == 'production' ? '/api/' : 'http://gastro.cz.local/api/';

export const navigation = {
    orders: {
        headings: ['0bjednávky'],
        navigation: {
            title: 'objednávky',
            identifier: 'orders',
            link: '/objednavky',
            icon: 'shopping-basket'
        },
        search: true,
        subnavigation: [],
        navVisible: true
    },
    recipes: {
        headings: ['Recepty'],
        navigation: {
            title: 'recepty',
            identifier: 'recipes',
            link: '/recepty',
            icon: 'utensils'
        },
        search: true,
        subnavigation: [],
        navVisible: true
    },
    warehouse: {
        headings: ['Sklad', 'Nákupy'],
        navigation: {
            title: 'sklad',
            identifier: 'warehouse',
            link: '/sklad/stav-skladu',
            icon: 'warehouse',
            icon2: 'cart-arrow-down'
        },
        search: true,
        subnavigation: [
            {
                title: 'nákupy',
                identifier: 'warehouse',
                link: '/sklad'
            },
            {
                title: 'stav skladu',
                identifier: 'warehouseStatus',
                link: '/sklad/stav-skladu'
            }
        ],
        navVisible: true
    },
    warehouseStatus: {
        headings: ['Sklad', 'Stav skladu'],
        navigation: {
            title: 'sklad',
            identifier: 'warehouse',
            link: '/sklad/stav-skladu',
            icon: 'warehouse'
        },
        search: true,
        subnavigation: [
            {
                title: 'nákupy',
                identifier: 'warehouse',
                link: '/sklad'
            },
            {
                title: 'stav skladu',
                identifier: 'warehouseStatus',
                link: '/sklad/stav-skladu'
            }
        ],
        navVisible: false
    },
    sessions: {
        headings: ['Turnusy'],
        navigation: {
            title: 'turnusy',
            identifier: 'sessions',
            link: '/turnusy',
            icon: 'clock'
        },
        search: true,
        subnavigation: [],
        navVisible: true
    },
    settings: {
        headings: ['Nastavení', 'Kategorie surovin'],
        navigation: {
            title: 'nastavení',
            identifier: 'settings',
            link: '/nastaveni/kategorie-surovin',
            icon: 'cog'
        },
        search: true,
        subnavigation: [
            {
                title: 'kategorie surovin',
                identifier: 'settings',
                link: '/nastaveni/kategorie-surovin'
            },
            {
                title: 'suroviny',
                identifier: 'settingsIngredients',
                link: '/nastaveni/suroviny'
            }
        ],
        navVisible: true
    },
    settingsIngredients: {
        headings: ['Nastavení', 'Suroviny'],
        navigation: {
            title: 'nastavení',
            identifier: 'settings',
            link: '/nastaveni/suroviny',
            icon: 'cog'
        },
        search: true,
        subnavigation: [
            {
                title: 'kategorie surovin',
                identifier: 'settings',
                link: '/nastaveni/kategorie-surovin'
            },
            {
                title: 'suroviny',
                identifier: 'settingsIngredients',
                link: '/nastaveni/suroviny'
            }
        ],
        navVisible: false
    },
    log: {
        headings: ['Log'],
        navigation: {
            title: 'log',
            identifier: 'log',
            link: '/log',
            icon: 'history'
        },
        search: true,
        subnavigation: [],
        navVisible: true
    },
    separator: {
        headings: [],
        navigation: {},
        search: false,
        subnavigation: [],
        navVisible: true
    },
    profile: {
        headings: ['Správa profilu'],
        navigation: {
            title: 'profil',
            identifier: 'profile',
            link: '/profil',
            icon: 'user'
        },
        search: false,
        subnavigation: [],
        navVisible: true
    },
    logout: {
        headings: ['Odhlásit'],
        navigation: {
            title: 'odhlásit',
            identifier: 'logout',
            link: '/',
            icon: 'sign-out-alt'
        },
        search: false,
        subnavigation: [],
        navVisible: true
    }
}