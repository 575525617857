import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from "react-redux"

import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import 'react-day-picker/lib/style.css'
import '../node_modules/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox.css'
import './styles/main.scss'

import { configureStore } from './store/configureStore'

import setAuthorizationToken from './utils/setAuthToken'
import jwtDecode from 'jwt-decode'
import { setCurrentUser } from './actions/AuthActions'
import App from "./App"
import ToastrContainer from "react-toastr-basic"

const store = configureStore()

if (localStorage.jwtToken) {
    setAuthorizationToken(localStorage.jwtToken)
    store.dispatch(setCurrentUser(jwtDecode(localStorage.jwtToken)))
}

ReactDOM.render(
    <Provider store={store}>
        <>
            <App />
            <ToastrContainer />
        </>
    </Provider>,
  document.getElementById('root')
)
