import {
    REQUEST_CATEGORIES,
    RECEIVE_CATEGORIES,
    ERROR_CATEGORIES
} from '../constants/types'
import { initialFetchState } from '../constants/initialState'


export default (state = initialFetchState, action = {}) => {
    switch(action.type) {
        case REQUEST_CATEGORIES:
            return Object.assign({}, state, {
                isFetching: true,
                message: '',
                errors: []
            })
        case RECEIVE_CATEGORIES:
            return Object.assign({}, state, {
                isFetching: false,
                items: action.ingredientsCategories,
                message: action.message
            })
        case ERROR_CATEGORIES:
            return Object.assign({}, state, {
                isFetching: false,
                errors: action.errors
            })
        default: return state;
    }
}