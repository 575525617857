import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import PropTypes from 'prop-types'
import Header from "../common/Header"
import Navigation from "../common/Navigation"
import Box from "../common/Box"
import IngredientsList from "../common/IngredientsList"
import DisplayBox from "../common/DisplayBox"
import BoxAddRecipe from "../common/BoxAddRecipe"
import Spinner from "../common/Spinner"
import Empty from "../common/Empty"
import {Row} from "react-bootstrap"
import {setActiveMenuItem} from '../../actions/NavigationActions'
import {validateInput, validateIngredient} from '../../utils/validations/recipes'
import {confirm} from '../../utils/confirm'
import {ToastDanger} from 'react-toastr-basic'
import {ToastSuccess} from 'react-toastr-basic'
import isEmpty from 'lodash/isEmpty'

import {
    getRecipes,
    addRecipe,
    updateRecipe,
    deleteRecipe,
    duplicateRecipe
} from '../../actions/RecipesActions'

import {getUnits} from '../../actions/UnitsActions'
import {getIngredients} from '../../actions/IngredientsActions'

class Recipes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_new: true,
            newItem: {
                name: {
                    id: '',
                    name: '',
                    changed: false
                },
                ingredients: [{
                    unit: '',
                    quantity: '',
                    ingredient: '',
                    is_new: true,
                    changed: false,
                    is_old: true
                }],
                instructions: '',
            },
            displayItem: {},
            errors: [],
            counter: 0,
        }

        props.actions.setActiveMenuItem('recipes');
        props.actions.getIngredients();
        props.actions.getUnits();
        props.actions.getRecipes();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.counter != prevState.counter) {
            const {errors} = this.state;
            Object.keys(errors).map(key =>
                ToastDanger(errors[key])
            );
        }
        if (this.props != prevProps) {
            const {errors, message} = this.props.recipes;
            if (errors) {
                errors.map((item) =>
                    ToastDanger(item)
                );
            }
            if (message && message != prevProps.recipes.message) {
                ToastSuccess(message);
            }
        }
    }

    isValid() {
        const {errors, isValid} = validateInput(this.state.newItem);

        if (!isValid) {
            this.setState({errors});
        }
        return isValid;
    }

    isIngredientValid(data) {
        const {errors, isValid} = validateIngredient(data);

        if (!isValid) {
            this.setState({errors});
        }
        return isValid;
    }


    onClickDelete(item) {
        confirm('delete', item.name, () => this.deleteItem(item.id_dish), () => void(0));
    }

    onClickDuplicate(item) {
        confirm('duplicate', item.name, () => this.duplicateItem(item.id_dish), () => void(0));
    }

    onClickNew(e) {
        this.setState({counter: this.state.counter + 1});
        const {is_new, newItem} = this.state;
        let ingredients = newItem.ingredients.slice(0);
        ingredients.pop();
        if (this.isValid()) {
            if (is_new) {
                this.props.actions.addRecipe({dish: newItem.name, ingredients: ingredients, instructions: newItem.instructions}).then(res => {
                    if(res == true){
                        this.resetState();
                    }
                })
                // remove last empty item
            } else {
                this.props.actions.updateRecipe({dish: newItem.name, ingredients: ingredients, instructions: newItem.instructions}).then(res => {
                    if(res == true){
                        this.resetState();
                    }
                })
                // remove last empty item
            }
        }
    }

    onChangeDish(e){
        let newItem = this.state.newItem
        if(e.target.name == 'dishName'){
            newItem.name.name = e.target.value
            newItem.name.changed = true
        }else{
            newItem[e.target.name] = e.target.value
        }
        this.setState({newItem: newItem})
    }

    onSelectIngredient(e, index) {
        let newItem = this.state.newItem;
        newItem.ingredients[index].ingredient = e;
        newItem.ingredients[index].unit = '';
        this.setState({newItem: newItem});
    }

    onDeleteIgredient(name, index) {
        confirm('delete', name, () => this.deleteIngredient(index), () => void(0));
    }

    deleteIngredient(index){
        let newItem = this.state.newItem;
        newItem.ingredients.splice(index, 1);
        this.setState({newItem: newItem});
    }

    onSelectUnit(unit, index) {
        let newItem = this.state.newItem;
        newItem.ingredients[index].unit = unit;
        newItem.ingredients[index].changed = true;
        this.setState({newItem: newItem});
    }

    onIngredientCreate(label, index) {
        let newItem = this.state.newItem;
        newItem.ingredients[index].ingredient = {label: label, value: ''};
        this.setState({newItem: newItem});
    }

    onChangeIngredient(e, index) { //quantity
        let value = parseFloat(e.target.value);
        if(isNaN(value)){
            value = '';
        }
        let newItem = this.state.newItem;
        newItem.ingredients[index][e.target.name] = value;
        newItem.ingredients[index].changed = true;
        this.setState({newItem: newItem});
    }

    onAddIngredient(name, index) {
        this.addIngredient(index)
    }

    addIngredient(index) {
        this.setState({counter: this.state.counter + 1});
        let newItem = this.state.newItem;
        if (this.isIngredientValid(newItem.ingredients[index])) {
            newItem.ingredients[index].is_new = false;
            newItem.ingredients[index].is_old = false;
            const emptyItem = {
                unit: '',
                quantity: '',
                ingredient: '',
                is_new: true,
                changed: false,
                is_old: true
            }
            newItem.ingredients.push(emptyItem);
            this.setState({newItem: newItem, errors: [], counter: 0});
        }
    }



    onClickDisplay(item) {
        this.setState({displayItem: item});
    }

    onClickEdit(item) {
        const ingredients = item.ingredients.map((itm, index) => {
                return {
                    unit: itm.id_unit,
                    quantity: itm.quantity,
                    ingredient: {
                        value: itm.id_ingredients,
                        label: itm.ingredient,
                        id_unit_category: itm.id_unit_category,
                    },
                    is_new: false,
                    changed: false,
                    is_old: true
                }
            }
        );

        const newItem = {
            name: {
                id: item.id_dish,
                name: item.name,
                changed: false
            },
            ingredients: [...ingredients,
                {
                    unit: '',
                    quantity: '',
                    ingredient: '',
                    is_new: true,
                    changed: false,
                    is_old: true
                }],
            instructions: item.instructions
        }

        this.setState({is_new: false, newItem: newItem, displayItem: {}});
    }

    onClickCloseDisplay() {
        this.setState({displayItem: {}});
    }

    onClickReset() {
        this.resetState();
    }

    deleteItem(id) {
        this.props.actions.deleteRecipe({id_dish: id});
        this.resetState();
    }

    duplicateItem(id) {
        this.props.actions.duplicateRecipe({id_dish: id});
        this.resetState();
    }

    resetState() {
        this.setState({
            is_new: true,
            newItem: {
                name: {
                    id: '',
                    name: '',
                    changed: false
                },
                ingredients: [{
                    unit: '',
                    quantity: '',
                    ingredient: '',
                    is_new: true,
                    changed: false,
                    is_old: true
                }],
                instructions: ''
            },
            displayItem: {},
            errors: [],
            counter: 0,
        });
    }

    render() {
        const {is_new, displayItem, newItem} = this.state;
        const {recipes, search} = this.props;
        const items = search ?
            recipes.items.filter(item => item.name.toLocaleLowerCase().includes(search.toLowerCase()))
            :
            recipes.items;
        const boxes = items.length ? items.map(item => {
            const controls = item.order_exists ? [
                {
                    label: 'zobrazit',
                    href: '#top',
                    onClick: () => this.onClickDisplay(item)
                },
                {
                    label: 'duplikovat',
                    onClick: () => this.onClickDuplicate(item)
                },
                {
                    label: 'smazat',
                    onClick: () => this.onClickDelete(item)
                }
            ] : [
                {
                    label: 'zobrazit',
                    href: '#top',
                    onClick: () => this.onClickDisplay(item)
                },
                {
                    label: 'upravit',
                    href: '#top',
                    onClick: () => this.onClickEdit(item)
                },
                {
                    label: 'duplikovat',
                    onClick: () => this.onClickDuplicate(item)
                },
                {
                    label: 'smazat',
                    onClick: () => this.onClickDelete(item)
                }
            ]

            return (
                <Box
                    active={item.id_dish === newItem.name.id}
                    key={item.id_dish}
                    heading={item.name}
                    subheading=''
                    type="vertical"
                    warningType=""
                    controls={controls}
                    content={
                        <div className="box-content-inner">
                            <IngredientsList items={item.ingredients}/>
                        </div>
                    }
                    md={6}
                    sm={12}
                />
            )
        }) : recipes.isFetching ? null : <Empty/>;

        return (
            <div>
                <Navigation/>
                <div className="main-page">
                    <Header/>
                    <div className="main-content" id="top">
                        <Spinner visible={recipes.isFetching}/>
                        {
                            !isEmpty(displayItem) ?
                                <DisplayBox item={displayItem} onClose={() => this.onClickCloseDisplay()}/>
                                :
                                <Row>
                                    <BoxAddRecipe
                                        active={newItem.name.id}
                                        dishName={newItem.name.name}
                                        units={this.props.units.isFetching ? [] : this.props.units.items}
                                        ingredients={this.props.ingredients.isFetching ? [] : this.props.ingredients.items}
                                        instructions={newItem.instructions}
                                        items={newItem.ingredients}
                                        button={{
                                            label: is_new ? "přidat" : "uložit",
                                            onClick: (e) => this.onClickNew(e)
                                        }}
                                        reset={() => this.onClickReset()}
                                        onChangeDish={(e) => this.onChangeDish(e)}
                                        onSelectIngredient={(e, index) => this.onSelectIngredient(e, index)}
                                        onChangeIngredient={(e, index) => this.onChangeIngredient(e, index)}
                                        onSelectUnit={(unit, index) => this.onSelectUnit(unit, index)}
                                        onDeleteIngredient={(name, index) => this.onDeleteIgredient(name, index)}
                                        onAddIngredient={(name, index) => this.onAddIngredient(name, index)}
                                        onIngredientCreate={(e, index) => this.onIngredientCreate(e, index)}
                                    />
                                </Row>
                        }
                        <Row>
                            {boxes}
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

Recipes.propTypes = {
    actions: PropTypes.object.isRequired,
}


function mapStateToProps(state) {
    return {
        recipes: state.recipes,
        search: state.search,
        units: state.units,
        ingredients: state.ingredients
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            setActiveMenuItem,
            getRecipes,
            addRecipe,
            updateRecipe,
            deleteRecipe,
            duplicateRecipe,
            getUnits,
            getIngredients,
        }, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Recipes);