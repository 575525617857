import {
    REQUEST_RECIPES,
    RECEIVE_RECIPES,
    ERROR_RECIPES,
    RELOAD_RECIPES
} from '../constants/types'
import { initialFetchState } from '../constants/initialState'


export default (state = initialFetchState, action = {}) => {
    switch(action.type) {
        case REQUEST_RECIPES:
            return Object.assign({}, state, {
                isFetching: true,
                message: '',
                errors: []
            })
        case RECEIVE_RECIPES:
            return Object.assign({}, state, {
                isFetching: false,
                items: action.recipes,
                shouldReload: false,
                message: action.message
            })
        case ERROR_RECIPES:
            return Object.assign({}, state, {
                isFetching: false,
                shouldReload: false,
                errors: action.errors
            })
        case RELOAD_RECIPES:
            return Object.assign({}, state, {
                shouldReload: true,
            })
        default: return state;
    }
}