import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import PropTypes from 'prop-types'
import Header from "../common/Header"
import Navigation from "../common/Navigation"
import Box from "../common/Box"
import BoxAddSimple from "../common/BoxAddSimple"
import Spinner from "../common/Spinner"
import Empty from "../common/Empty"
import {Row, Col} from "react-bootstrap"
import {setActiveMenuItem} from '../../actions/NavigationActions'
import {validateInput, validatePrintInput} from '../../utils/validations/sessions'
import {confirm} from '../../utils/confirm'
import {ToastDanger} from 'react-toastr-basic'
import {ToastSuccess} from 'react-toastr-basic'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import isEmpty from 'lodash/isEmpty'
import Switch from "react-switch"
import classnames from 'classnames'

import {
    getSessions,
    addSession,
    updateSession,
    activateSession,
    deactivateSession,
    finalizeSession,
    getReportSession,
} from '../../actions/SessionsActions';

class SettingsPageSessions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameValue: '',
            newLabel: 'přidat',
            selectedItem: {},
            errors: [],
            counter: 0,
            printView: false,
            selectAll: false,
            selectedSessions: []
        }
        props.actions.setActiveMenuItem('sessions');
        props.actions.getSessions();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.counter != prevState.counter) {
            const {errors} = this.state;
            Object.keys(errors).map(key =>
                ToastDanger(errors[key])
            );
        }
        if (this.props != prevProps) {
            const {errors, message} = this.props.sessions;
            if (errors) {
                errors.map((item) =>
                    ToastDanger(item)
                );
            }
            if (message && message != prevProps.sessions.message) {
                ToastSuccess(message);
            }
        }
    }

    isValid() {
        const {errors, isValid} = validateInput(this.state);

        if (!isValid) {
            this.setState({errors});
        }
        return isValid;
    }

    isPrintValid() {
        const {errors, isValid} = validatePrintInput(this.state);
        if (!isValid) {
            this.setState({errors});
        }
        return isValid;
    }

    onClickEdit(item) {
        this.setState({nameValue: item.name, newLabel: 'uložit', selectedItem: item});
    }

    onClickFinalize(item) {
        confirm('finalize', item.name, () => this.finalizeSession(item.id), () => void(0));
    }

    onClickNew(e) {
        this.setState({counter: this.state.counter + 1});
        if (this.isValid()) {
            if (isEmpty(this.state.selectedItem)) {
                this.props.actions.addSession({name: this.state.nameValue});
            } else {
                const data = {
                    name: this.state.nameValue,
                    id: this.state.selectedItem.id
                }
                this.props.actions.updateSession(data);
            }
            this.resetState();
        }
    }

    onInputChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onClickReset() {
        this.resetState();
    }

    onChangePrint() {
        this.setState({printView: !this.state.printView});
    }

    onClickSelectAll() {
        const selected = this.state.selectAll ? [] : this.props.sessions.items.map((item) => item.id)
        this.setState({selectAll: !this.state.selectAll, selectedSessions: selected});
    }

    onClickSelect(id) {
        let selectedSessions = this.state.selectedSessions;
        const index = selectedSessions.findIndex(i => i == id);
        if (index != -1) {
            selectedSessions.splice(index, 1);
        } else {
            selectedSessions.push(id);
        }
        const selectAll = isEmpty(selectedSessions) && this.state.selectAll ? false : this.state.selectAll;
        this.setState({selectedSessions: selectedSessions, selectAll: selectAll});
    }

    finalizeSession(id) {
        this.props.actions.finalizeSession({id: id});
        this.resetState();
    }

    onClickReport() {
        this.setState({counter: this.state.counter + 1});
        if (this.isPrintValid()) {
            this.resetState();
            this.props.actions.getReportSession({ids: this.state.selectedSessions})
        }
    }

    onClickToggleActivate(item) {
        if (item.pre_active) {
            this.props.actions.deactivateSession({id: item.id});
        } else {
            this.props.actions.activateSession({id: item.id});
        }
    }

    resetState() {
        this.setState({
            nameValue: '',
            newLabel: 'přidat',
            selectedItem: {},
            errors: [],
            counter: 0
        });
    }

    render() {
        const {nameValue, newLabel, selectedItem, printView} = this.state;
        const {sessions, search} = this.props;
        const items = search ?
            sessions.items.filter(item => item.name.toLocaleLowerCase().includes(search.toLowerCase()))
            :
            sessions.items;
        let pre_active_exists = false;
        items.map(item => {
            if (item.pre_active) {
                pre_active_exists = true;
            }
        })

        let id_active = 0;
        items.map(item => {
            if(item.active){
                id_active = item.id
            }
        })

        const boxes_future = items.length ? items.map(item => {
            const controls = printView ? [
                {
                    label: <FontAwesomeIcon
                        icon={this.state.selectedSessions.includes(item.id) ? ['far', 'check-circle'] : "circle"}
                        size="2x"/>,
                    onClick: () => this.onClickSelect(item.id)
                }
            ]
                : item.active ? [
                    {
                        label: 'upravit',
                        href: '#top',
                        onClick: () => this.onClickEdit(item)
                    },
                    {
                        label: 'uzavřít',
                        onClick: () => this.onClickFinalize(item)
                    }
                ] : item.finalized ? [
                    {
                        label: 'upravit',
                        href: '#top',
                        onClick: () => this.onClickEdit(item)
                    }
                ] : [
                    {
                        label: 'upravit',
                        href: '#top',
                        onClick: () => this.onClickEdit(item)
                    },
                    {
                        label: item.pre_active ? 'deaktivovat' : 'aktivovat',
                        onClick: () => this.onClickToggleActivate(item)
                    }
                ]
            if(item.id > id_active){
                return (
                    <Box
                        active={item.id === selectedItem.id}
                        key={item.id}
                        heading={item.name}
                        subheading={(item.finalized ? "uzavřeno: " : "vytvořeno: ") + item.date}
                        type="vertical"
                        warningType=""
                        controls={controls}
                        content={
                            <div className="box-content-inner">
                                {
                                    ((item.active && !pre_active_exists) || item.pre_active) ?
                                        <span className="number highlight">AKTIVNÍ</span>
                                        :
                                        <span className="number">NEAKTIVNÍ</span>
                                }
                            </div>
                        }
                    />
                )
            }
        }) : sessions.isFetching ? null : <Empty/>;

        const boxes = items.length ? items.map(item => {
            const controls = printView ? [
                {
                    label: <FontAwesomeIcon
                        icon={this.state.selectedSessions.includes(item.id) ? ['far', 'check-circle'] : "circle"}
                        size="2x"/>,
                    onClick: () => this.onClickSelect(item.id)
                }
            ]
                : item.active ? [
                    {
                        label: 'upravit',
                        href: '#top',
                        onClick: () => this.onClickEdit(item)
                    },
                    {
                        label: 'uzavřít',
                        onClick: () => this.onClickFinalize(item)
                    }
                ] : item.finalized ? [
                    {
                        label: 'upravit',
                        href: '#top',
                        onClick: () => this.onClickEdit(item)
                    }
                ] : [
                    {
                        label: 'upravit',
                        href: '#top',
                        onClick: () => this.onClickEdit(item)
                    },
                    {
                        label: item.pre_active ? 'deaktivovat' : 'aktivovat',
                        onClick: () => this.onClickToggleActivate(item)
                    }
                ]
            if(item.id <= id_active){
                return (
                    <Box
                        active={item.id === selectedItem.id}
                        key={item.id}
                        heading={item.name}
                        subheading={(item.finalized ? "uzavřeno: " : "vytvořeno: ") + item.date}
                        type="vertical"
                        warningType=""
                        controls={controls}
                        content={
                            <div className="box-content-inner">
                                {
                                    ((item.active && !pre_active_exists) || item.pre_active) ?
                                        <span className="number highlight">AKTIVNÍ</span>
                                        :
                                        <span className="number">NEAKTIVNÍ</span>
                                }
                            </div>
                        }
                    />
                )
            }
        }) : sessions.isFetching ? null : <Empty/>;

        const switchButton = <div className="switch-wrapper">
            <label htmlFor="print-switch">
                <Switch
                    onChange={() => this.onChangePrint()}
                    checked={printView}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    offColor="#CCC"
                    onColor="#9F0F30"
                    height={20}
                    width={40}
                    activeBoxShadow="0 0 2px 3px #9F0F30"
                    id="print-switch"
                />
                <span>Režim pro report</span>
            </label>
        </div>

        const filterButtons = <div>
            <button
                type="button"
                className="btn btn-theme"
                role="radio"
                name="1"
                onClick={() => this.onClickReport()}
            >vytisknout vybrané
            </button>
        </div>

        const adittionalButtons = <div>
            <button
                type="button"
                className={classnames("btn btn-theme", this.state.selectAll ? "active" : "")}
                role="radio"
                name="btn1"
                onClick={() => this.onClickSelectAll()}
            >vybrat vše
            </button>
        </div>

        return (
            <div>
                <Navigation/>
                <div className="main-page">
                    <Header/>
                    <div className="main-content" id="top">
                        <Spinner visible={sessions.isFetching}/>
                        <Row className="controls-row">
                            <Col xs={12}>
                                <div className="controls-row-wrapper has-switch">
                                    {switchButton}
                                    {
                                        printView ?
                                            <div className="filters-wrapper">
                                                {filterButtons}
                                                {adittionalButtons}
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            </Col>
                        </Row>
                        {
                            printView
                                ?
                                null
                                :
                                <Row>
                                    <BoxAddSimple
                                        active={!isEmpty(selectedItem)}
                                        fields={[
                                            {
                                                type: 'text',
                                                label: 'Nový turnus (nákupní seznam)',
                                                value: nameValue,
                                                name: 'nameValue',
                                                onChange: (e) => this.onInputChange(e)
                                            }
                                        ]}
                                        button={{label: newLabel, onClick: (e) => this.onClickNew(e)}}
                                        reset={() => this.onClickReset()}
                                    />
                                </Row>
                        }
                        <Row>
                            <h2>Nákupní seznamy</h2>
                            {boxes_future}
                        </Row>
                        <Row>
                            <h2>Současné a uzavřené turnusy</h2>
                            {boxes}
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

SettingsPageSessions.propTypes = {
    actions: PropTypes.object.isRequired,
}


function mapStateToProps(state) {
    return {
        sessions: state.sessions,
        search: state.search
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            setActiveMenuItem,
            getSessions,
            addSession,
            updateSession,
            activateSession,
            deactivateSession,
            finalizeSession,
            getReportSession
        }, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingsPageSessions);