import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import PropTypes from 'prop-types'
import Header from "../common/Header"
import Navigation from "../common/Navigation"
import Box from "../common/Box"
import BoxAddWarehouseCorrection from "../common/BoxAddWarehouseCorrection"
import Spinner from "../common/Spinner"
import Empty from "../common/Empty"
import {Row, Col} from "react-bootstrap"
import {setActiveMenuItem} from '../../actions/NavigationActions'
import {validateInput} from '../../utils/validations/warehouseStatus'
import {ToastDanger} from 'react-toastr-basic'
import {ToastSuccess} from 'react-toastr-basic'
import {MobileView} from "react-device-detect"
import Scanner from '../common/Scanner'
import classnames from 'classnames'
import Switch from "react-switch"
import isEmpty from 'lodash/isEmpty'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {
    getIngredients
} from '../../actions/IngredientsActions';
import {getUnits} from '../../actions/UnitsActions'
import {getIngredientsCategories} from '../../actions/IngredientsCategoriesActions'
import {getWarehouseStatus, addWarehouseCorrection, printWarehouse} from '../../actions/WarehouseStatusActions'


class WarehouseStatusPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newItem: {
                nameValue: '',
                quantityValue: '',
                unitValue: '',
                barcodeValue: '',
            },
            filter: {
                category: [],
                status: ''
            },
            printView: false,
            errors: [],
            counter: 0,
            showScanner: false
        }
        props.actions.setActiveMenuItem('warehouseStatus');
        props.actions.getIngredients();
        props.actions.getIngredientsCategories();
        props.actions.getWarehouseStatus();
        props.actions.getUnits();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.counter != prevState.counter) {
            const {errors} = this.state;
            Object.keys(errors).map(key =>
                ToastDanger(errors[key])
            );
        }
        if (this.props != prevProps) {
            const {errors, message} = this.props.warehouseStatus;
            if (errors) {
                errors.map((item) =>
                    ToastDanger(item)
                );
            }
            if (message && message != prevProps.warehouseStatus.message) {
                ToastSuccess(message);
            }
        }
    }

    onChangePrint() {
        this.setState({printView: !this.state.printView});
    }

    onPrintButtonClick(e) {
        this.setState({counter: this.state.counter + 1});
        const filter = e.target.name == '1' ? this.state.filter : {
            category: [],
            status: ''
        }
        this.props.actions.printWarehouse({filter: filter})
    }

    onSelectCategoryFilter(id) {
        let currentFilter = this.state.filter;
        const index = currentFilter.category.findIndex(i => i == id);
        if (index != -1) {
            currentFilter.category.splice(index, 1);
        } else {
            currentFilter.category.push(id);
        }

        this.setState({filter: currentFilter});
    }

    onSelectStatusFilter(filter){
        let currentFilter = this.state.filter;
        if (currentFilter.status == filter) {
            currentFilter.status = '';
        } else {
            currentFilter.status = filter;
        }
        this.setState({filter: currentFilter});
    }

    isValid() {
        const {errors, isValid} = validateInput(this.state);

        if (!isValid) {
            this.setState({errors});
        }
        return isValid;
    }

    handleBarcodeClick() {
        this.setState({showScanner: true});
    }

    onDetected(result) {

        let newItem = this.state.newItem;
        newItem.barcodeValue = result;

        const items = this.props.ingredients.items;
        let found = false;
        for (var i = 0; i < items.length; i++) {
            for (var j = 0; j < items[i].barcodes.length; j++) {
                if (items[i].barcodes[j].barcode == result) {
                    newItem.nameValue = {
                        value: items[i].value,
                        label: items[i].label,
                        id_unit_category: items[i].id_unit_category
                    };
                    newItem.quantityValue = items[i].barcodes[j].quantity ? items[i].barcodes[j].quantity : '';
                    newItem.unitValue = items[i].barcodes[j].id_unit_category ? items[i].barcodes[j].id_unit_category : '';
                    found = true;
                    break;
                }
            }
            if (found) break;
        }
        this.setState({newItem: newItem});
    }

    onCloseScanner() {
        this.setState({showScanner: false});
    }

    onClickNew(e) {
        this.setState({counter: this.state.counter + 1});
        if (this.isValid()) {
            const {newItem} = this.state;
                this.props.actions.addWarehouseCorrection({
                    ingredient: newItem.nameValue,
                    quantity: newItem.quantityValue,
                    unit: newItem.unitValue,
                    barcode: newItem.barcodeValue,
                });

            this.resetState();
        }
    }

    onClickAdd(item) {
        let newItem = {
            nameValue: item,
            quantityValue: '',
            unitValue: '',
        }
        this.setState({newItem: newItem});
    }

    onIngredientCreate(label) {
        let newItem = this.state.newItem;
        newItem.nameValue = {label: label, value: ''};
        this.setState({newItem: newItem});
    }

    onSelectIngredient(e) {
        let newItem = this.state.newItem;
        newItem.nameValue = e;
        newItem.unitValue = '';
        this.setState({newItem: newItem});
    }

    onSelectUnit(unit) {
        let newItem = this.state.newItem;
        newItem.unitValue = unit;
        this.setState({newItem: newItem});
    }

    onChangeIngredient(e) { //inputs
        let value = parseFloat(e.target.value);
        if (isNaN(value)) {
            value = '';
        }
        let newItem = this.state.newItem;
        newItem[e.target.name] = value;
        this.setState({newItem: newItem});
    }

    onClickReset() {
        this.resetState();
    }

    onBarcodeRemove() {
        let newItem = this.state.newItem;
        newItem.barcodeValue = '';
        this.setState({newItem: newItem});
    }

    resetState() {
        this.setState({
            newItem: {
                nameValue: '',
                quantityValue: '',
                unitValue: '',
                barcodeValue: '',
            },
            filter: {
                category: [],
                status: ''
            },
            errors: [],
            counter: 0,
            showScanner: false
        });
    }

    render() {
        const {newItem, filter, showScanner, printView} = this.state;
        const {warehouseStatus, search, ingredientsCategories} = this.props;

         let items = !isEmpty(filter.category) ? warehouseStatus.items.filter(item => filter.category.includes(item.id_category))
         :
             warehouseStatus.items;

         items = filter.status ? items.filter(item => item.status == filter.status)
         :
         items;


         items = search ? items.filter(item => item.label.toLocaleLowerCase().includes(search.toLowerCase()))
         :
         items;

        const boxes = items.length ? items.map(item => {
            const controls = [
                {
                    label: 'upravit',
                    href: '#top',
                    onClick: () => this.onClickAdd(item)
                }
            ]
            return (
                <Box
                    active={false}
                    key={item.value}
                    heading={item.label}
                    subheading={item.category}
                    type="vertical"
                    warningType={item.status}
                    controls={controls}
                    content={
                        <div className="box-content-inner">
                            <span className="number highlight">{item.quantity}</span>
                            <span className="label">{item.unit}</span>
                        </div>
                    }
                />
            )
        }) : warehouseStatus.isFetching ? null : <Empty/>;

        const switchButton = <div className="switch-wrapper">
            <label htmlFor="print-switch">
                <Switch
                    onChange={() => this.onChangePrint()}
                    checked={printView}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    offColor="#CCC"
                    onColor="#9F0F30"
                    height={20}
                    width={40}
                    activeBoxShadow="0 0 2px 3px #9F0F30"
                    id="print-switch"
                />
                <span>Možnosti tisku</span>
            </label>
        </div>

        const printButtons = <div>
            <button
                type="button"
                className="btn btn-theme"
                role="radio"
                name="1"
                onClick={(e) => this.onPrintButtonClick(e)}
            >vytisknout s filtry
            </button>
            <button
                type="button"
                className="btn btn-theme"
                role="radio"
                name="2"
                onClick={(e) => this.onPrintButtonClick(e)}
            >vytisknout bez filtrů
            </button>
        </div>

        const filterButtons = ingredientsCategories.items.map((item, index) =>
            <button
                key={index}
                type="button"
                className={classnames("btn btn-theme", filter.category.includes(item.id) ? "active" : "")}
                role="radio"
                name="filter"
                onClick={(e) => this.onSelectCategoryFilter(item.id)}
            >{item.name}
            </button>
        )

        const filterButtons2 = [<button
                key='button_status'
                type="button"
                title="množství je <= 0"
                className={classnames("btn btn-theme", filter.status == 'danger' ? "active" : "")}
                role="radio"
                name="filter"
                onClick={(e) => this.onSelectStatusFilter('danger')}
            ><FontAwesomeIcon icon="exclamation-circle"/> nedostatečné množství
            </button>
            ,
        <button
                key='button_status2'
                type="button"
                title="množství je menší než 1/2 základní jednotky"
                className={classnames("btn btn-theme", filter.status == 'warning' ? "active" : "")}
                role="radio"
                name="filter"
                onClick={(e) => this.onSelectStatusFilter('warning')}
            ><FontAwesomeIcon icon="exclamation"/> docházející množství
            </button>]

        return (
            <div>
                <Navigation/>
                <div className="main-page">
                    <Header/>
                    <div className="main-content" id="top">
                        <Spinner visible={warehouseStatus.isFetching}/>
                        <Row className="controls-row">
                            <Col xs={12}>
                                <div className="controls-row-wrapper has-switch">
                                    {switchButton}
                                    {
                                        printView ?
                                            <div className="filters-wrapper">
                                                {printButtons}
                                                <div/>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row className="controls-row">
                            <Col xs={12}>
                                <div className="controls-row-wrapper">
                                    <div className="filters-wrapper filter-categories">
                                        <div><span>Filtr: </span></div>
                                        <div>
                                            {filterButtons}
                                            {filterButtons2}
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <MobileView viewClassName="barcode">
                                    <div onClick={() => this.handleBarcodeClick()}>
                                        <FontAwesomeIcon icon="barcode" size="3x"/>
                                    </div>
                                    <div>{newItem.barcodeValue ? "Načíst nový kód" : "Načíst kód"}</div>
                                    {newItem.barcodeValue ?
                                        <div className="barcode-remove" onClick={() => this.onBarcodeRemove()}>
                                            <FontAwesomeIcon icon="times" size="2x"/></div> : null}
                                    {showScanner ? <Scanner onDetected={(result) => this.onDetected(result)}
                                                            onClose={() => this.onCloseScanner()}/> : null}
                                </MobileView>
                            </Col>
                        </Row>
                        <Row>
                            <BoxAddWarehouseCorrection
                                active={newItem.nameValue || newItem.unitValue || newItem.quantityValue || newItem.barcodeValue}
                                item={newItem}
                                units={this.props.units.isFetching ? [] : this.props.units.items}
                                ingredients={this.props.ingredients.isFetching ? [] : this.props.ingredients.items}
                                button={{
                                    label: 'zadat',
                                    onClick: (e) => this.onClickNew(e)
                                }}
                                reset={() => this.onClickReset()}
                                onSelectIngredient={(e) => this.onSelectIngredient(e)}
                                onChangeIngredient={(e) => this.onChangeIngredient(e)}
                                onSelectUnit={(unit) => this.onSelectUnit(unit)}
                                onIngredientCreate={(e) => this.onIngredientCreate(e)}
                            />
                        </Row>
                        <Row>
                            {boxes}
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

WarehouseStatusPage.propTypes = {
    actions: PropTypes.object.isRequired,
}


function mapStateToProps(state) {
    return {
        ingredients: state.ingredients,
        ingredientsCategories: state.ingredientsCategories,
        warehouseStatus: state.warehouseStatus,
        units: state.units,
        search: state.search
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            setActiveMenuItem,
            getIngredientsCategories,
            getIngredients,
            getUnits,
            getWarehouseStatus,
            addWarehouseCorrection,
            printWarehouse
        }, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WarehouseStatusPage);