import axios from 'axios'
import {
    REQUEST_RECIPES,
    RECEIVE_RECIPES,
    ERROR_RECIPES,
    RELOAD_RECIPES
} from '../constants/types'
import  {fetchUrl} from '../constants/constants'
import {receiveIngredients} from './IngredientsActions'
import {receiveDishes} from './DishesActions'
import {reloadWarehouseStatus} from './WarehouseStatusActions'
import {reloadWarehouse} from './WarehouseActions'
import isEmpty from 'lodash/isEmpty'

function requestRecipes() {
    return {
        type: REQUEST_RECIPES
    };
}

function receiveRecipes(data, message = '') {
    return {
        type: RECEIVE_RECIPES,
        recipes: data,
        message: message
    }
}

function failedRecipes(data) {
    return {
        type: ERROR_RECIPES,
        errors: data
    }
}

export function reloadRecipes(){
    return {
        type: RELOAD_RECIPES
    }
}

function shouldFetchRecipes(state, basedOnData = false) {
    const fetch = state.recipes;
    if (!fetch) {
        return true
    } else if (fetch.isFetching) {
        return false
    } else if (fetch.shouldReload) {
        return true
    } else if (basedOnData) {
        if(fetch.items.length == 0){
            return true
        }else{
            return false
        }
    } else {
        return true
    }
}

function doFetchRecipes(data, request) {
    return dispatch => {
        dispatch(requestRecipes());
        return axios.post(fetchUrl + request, data).then(res => {
            const response = res.data;
                if (response.data.success) {
                    dispatch(receiveRecipes(response.data.data.recipes, response.message));
                    if(!isEmpty(response.data.data.ingredients)){
                        dispatch(receiveIngredients(response.data.data.ingredients))
                    }
                    if(!isEmpty(response.data.data.dishes)){
                        dispatch(receiveDishes(response.data.data.dishes))
                    }
                    if(request != 'getRecipes'){
                        dispatch(reloadWarehouseStatus()) // warehouse status changed
                        dispatch(reloadWarehouse()) // warehouse changed
                    }
                    return true
                } else {
                    dispatch(failedRecipes([response.message]))
                    return false
                }
            }).catch(err => {
                return dispatch(failedRecipes(["Neočekávaná chyba"]))
            })
    }
}

function fetchRecipes(type, data = {}) {
    let request;
    let compareData;
    switch (type) {
        case 'get':
            request = 'getRecipes';
            compareData = true;
            break;
        case 'add':
            request = 'addRecipe';
            compareData = false;
            break;
        case 'update':
            request = 'updateRecipe';
            compareData = false;
            break;
        case 'delete':
            request = 'deleteRecipe';
            compareData = false;
            break;
        case 'duplicate':
            request = 'duplicateRecipe';
            compareData = false;
            break;
        default:
            request = 'getRecipes';
            compareData = false;
    }
    return (dispatch, getState) => {
        if (shouldFetchRecipes(getState(), compareData)) {
            return dispatch(doFetchRecipes(data, request))
        } else {
            return Promise.resolve()
        }
    }
}

export function getRecipes(data = {}){
    return fetchRecipes('get', data);
}

export function addRecipe(data = {}){
    return fetchRecipes('add', data);
}

export function updateRecipe(data = {}){
    return fetchRecipes('update', data);
}

export function deleteRecipe(data = {}){
    return fetchRecipes('delete', data);
}

export function duplicateRecipe(data = {}){
    return fetchRecipes('duplicate', data);
}