import validator from 'validator'
import isEmpty from 'lodash/isEmpty'

export function validateInput(data) {
    let errors = {};

    if (validator.isEmpty(data.nameValue)) {
        errors.nameValue = 'Vyplňte pole Název turnusu';
    }

    return {
        errors,
        isValid: isEmpty(errors)
    };
}

export function validatePrintInput(data) {
    let errors = {};
    if (isEmpty(data.selectedSessions)) {
        errors.selectedSessions = 'Vyberte alespoň jednu položku pro tisk';
    }

    return {
        errors,
        isValid: isEmpty(errors)
    };
}