import React from 'react'
import PropTypes from 'prop-types'
import {Col, Row, Table} from "react-bootstrap"
import {plural} from '../../utils/plural'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'

const DisplayPurchaseDetail = ({item, data, isFetching, onClose, onUpdate}) => {
    const data_ = isEmpty(data) ? {
        items: [],
        unit: '',
        avgPrice: 0
    } : data;

    const rows = data_.items.map((itm, idx) =>
            <tr key={idx}>
                <td>{itm.date}</td>
                <td>{itm.price} Kč</td>
                <td>{itm.waste} %</td>
                <td>{itm.quantity} {itm.unit}</td>
                <td>{itm.expiration ? moment(itm.expiration).format('D. M. YYYY') : 'neuvedeno'} &nbsp;{itm.expired ? <FontAwesomeIcon icon="exclamation-circle" className="color-danger"/> : null}</td>
                <td>{itm.user}</td>
                <td><button className="btn btn-theme" onClick={() => onUpdate(itm)}>upravit</button></td>
            </tr>
    )
    return (
        <Row>
            <Col sm={12}>
                <div className="box active display-box">
                    <div className="box-content">
                        <div className="purchase-detail-header">
                            <div>
                                <h3>{item.label}</h3>
                                <small>{item.category}</small>
                            </div>
                            <div className="purchase-detail-header-info">
                                <p>celkem
                                    <span> {data_.items.length} </span> {plural(data_.items.length, ["nákup", "nákupy", "nákupů"])}
                                </p>
                            </div>
                            <div className="purchase-detail-header-info">
                                <p>průměrná cena <span>{data_.avgPrice}</span> Kč/{data_.unit}</p>
                            </div>
                        </div>
                        <div className="purchase-detail-content">
                            {
                                isFetching ?
                                    <div className="empty">aktualizuji data</div>
                                    :
                                data_.items.length ?
                                    <Table striped responsive hover>
                                        <thead>
                                        <tr>
                                            <th>Datum nákupu</th>
                                            <th>Cena</th>
                                            <th>Odpad</th>
                                            <th>Množství</th>
                                            <th>Expirace</th>
                                            <th>Zadal</th>
                                            <th>Akce</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {rows}
                                        </tbody>
                                    </Table>
                                    :
                                    <div className="empty">Žádný nákup nebyl doposud zadán</div>
                            }
                        </div>
                    </div>
                    <div onClick={onClose} className="close-icon-wrapper">
                        <FontAwesomeIcon icon={['far', 'times-circle']} className="close-icon" size="2x"/>
                        <FontAwesomeIcon icon={['fas', 'times-circle']} className="close-icon-hover" size="2x"/>
                    </div>
                </div>
            </Col>
        </Row>
    );
}

DisplayPurchaseDetail.propTypes = {
    item: PropTypes.object,
    data: PropTypes.object,
}

export default DisplayPurchaseDetail;