import React from 'react'
import {connect} from 'react-redux'

class Empty extends React.Component {
    render() {
        const inner = this.props.search ? <span>Položka <strong>{this.props.search}</strong> nenalezena</span> : <span>Žádná položka k zobrazení</span>;
        return (
            <div className="empty">
                {inner}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        search: state.search
    };
}

export default connect(
    mapStateToProps,
    null
)(Empty);
