import isEmpty from 'lodash/isEmpty'

export function validateInput(data) {
    let errors = {};

    if (isEmpty(data.nameValue)) {
        errors.nameValue = 'Vyplňte pole Název suroviny';
    }

    if (isEmpty(data.unitCategoryValue)) {
        errors.unitCategoryValue = 'Vyplňte pole Fyz. veličina';
    }

    return {
        errors,
        isValid: isEmpty(errors)
    };
}