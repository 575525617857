import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {Row, Col} from "react-bootstrap"

import {Redirect} from "react-router-dom"

class InvalidLinkForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redir: false,
        };
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({redir: true});
    }

    render() {
        if(this.state.redir){
            return <Redirect to="/zapomenute-heslo"/>
        }
        return (
            <form className="auth-form" onSubmit={(e) => this.onSubmit(e)}>
                <h1>Poslat nový email</h1>

                <div className="button-row">
                    <Row className="margin-right-0">
                        <Col lg={6} md={7} sm={6} xs={6}>
                            <button className="btn">Odeslat</button>
                        </Col>
                    </Row>
                </div>
            </form>
        );
    }
}

InvalidLinkForm.contextTypes = {
    router: PropTypes.object.isRequired
}


export default InvalidLinkForm;