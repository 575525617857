import React from 'react'
import PropTypes from 'prop-types'
import {Col, Row} from "react-bootstrap"
import IngredientsList from "../common/IngredientsList"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'


const DisplayBox = ({item, onClose}) => {
    return (
        <Row>
            <Col sm={12}>
                <div className="box active display-box">
                    <div className="box-content">
                        <h3>{item.name}</h3>
                        <IngredientsList items={item.ingredients} md={3} sm={6} size="large"/>
                        <div>
                            <h4>Postup:</h4>
                            <p>{item.instructions}</p>
                        </div>
                    </div>
                    <div onClick={onClose} className="close-icon-wrapper">
                        <FontAwesomeIcon icon={['far', 'times-circle']} className="close-icon" size="2x"/>
                        <FontAwesomeIcon icon={['fas', 'times-circle']} className="close-icon-hover" size="2x"/>
                    </div>
                </div>
            </Col>
        </Row>
    );
}

DisplayBox.propTypes = {
    item: PropTypes.object,
}

export default DisplayBox;