import axios from 'axios'
import {
    REQUEST_INGREDIENTS,
    RECEIVE_INGREDIENTS,
    ERROR_INGREDIENTS
} from '../constants/types'
import  {fetchUrl} from '../constants/constants'
import {reloadWarehouseStatus} from './WarehouseStatusActions'
import {reloadWarehouse} from './WarehouseActions'

function requestIngredients() {
    return {
        type: REQUEST_INGREDIENTS
    };
}

export function receiveIngredients(data, message = '') {
    return {
        type: RECEIVE_INGREDIENTS,
        ingredients: data,
        message: message
    }
}

function failedIngredients(data) {
    return {
        type: ERROR_INGREDIENTS,
        errors: data
    }
}

function shouldFetchIngredients(state, basedOnData = false) {
    const fetch = state.ingredients;
    if (!fetch) {
        return true
    } else if (fetch.isFetching) {
        return false
    } else if (basedOnData) {
        if(fetch.items.length == 0){
            return true
        }else{
            return false
        }
    } else {
        return true
    }
}

function doFetchIngredients(data, request) {
    return dispatch => {
        dispatch(requestIngredients());
        return axios.post(fetchUrl + request, data).then(res => {
            const response = res.data;
                if (response.data.success) {
                    dispatch(receiveIngredients(response.data.data, response.message))
                    dispatch(reloadWarehouseStatus()); // warehouse status changed
                    dispatch(reloadWarehouse()); // warehouse changed
                } else {
                    dispatch(failedIngredients([response.message]))
                }
            }).catch(err => {
                return dispatch(failedIngredients(["Neočekávaná chyba"]))
            })
    }
}

function fetchIngredients(type, data = {}) {
    let request;
    let compareData;
    switch (type) {
        case 'get':
            request = 'getIngredients';
            compareData = true;
            break;
        case 'add':
            request = 'addIngredient';
            compareData = false;
            break;
        case 'update':
            request = 'updateIngredient';
            compareData = false;
            break;
        default:
            request = 'getIngredients';
            compareData = true;
    }
    return (dispatch, getState) => {
        if (shouldFetchIngredients(getState(), compareData)) {
            return dispatch(doFetchIngredients(data, request))
        } else {
            return Promise.resolve()
        }
    }
}

export function getIngredients(data = {}){
    return fetchIngredients('get', data);
}

export function addIngredient(data = {}){
    return fetchIngredients('add', data);
}

export function updateIngredient(data = {}){
    return fetchIngredients('update', data);
}