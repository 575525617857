import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {Row, Col} from "react-bootstrap"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {plural} from '../../utils/plural'

const OrderBox = ({heading, subheading, warningType, controls, printView, count, countChildren, md, sm}) => {
    let controls_, icon_ = null;
    if (warningType) {
        let icon = null;
        switch (warningType){
            case 'danger':
                icon = <FontAwesomeIcon icon="exclamation-circle" size="3x"/>
                break
            case 'warning':
                icon = <FontAwesomeIcon icon="exclamation" size="2x"/>
                break
            case 'normal':
                icon = <FontAwesomeIcon icon="check" size="2x"/>
                break
            default:
                icon = null
        }
        icon_ = icon ? <div className={classnames("box-icons", warningType)}>{icon}</div> : null;
    }

    return (
        <Col md={md} sm={sm}>
            <div className="box order-box">
                <div className="box-content">
                    <div className="order-header clearfix">
                        <div className="order-heading">
                            <h3 title={heading}>{heading}</h3>
                            {subheading ? <small title={subheading}>{subheading}</small> : null}
                        </div>
                        <div className="order-icon">
                            {icon_}
                        </div>
                    </div>
                    {
                        printView ?
                            <div className="box-controls">
                                <div onClick={controls.print.onClick}>{controls.print.label}</div>
                            </div>
                            :
                            <div className="box-content-inner">
                                <Row className="meal-order">
                                    <Col md={7}>
                                        <span className="number highlight">{count}</span>
                                        <span
                                            className="label">{plural(count, ["běžná porce objednána", "běžné porce objednány", "běžných porcí objednáno"])}</span>
                                    </Col>
                                    <Col md={5}>
                                        <Row className="order-controls-outer">
                                            {
                                                controls.count.map((item, index) => {
                                                    return <Col sm={6} key={index} onClick={() => item.onClick(0)}>{item.label}</Col>
                                                })
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="meal-order">
                                    <Col md={7}>
                                        <span className="number highlight">{countChildren}</span>
                                        <span
                                            className="label">{plural(countChildren, ["malá porce objednána", "malé porce objednány", "malých porcí objednáno"])}</span>
                                    </Col>
                                    <Col md={5}>
                                        <Row className="order-controls-outer">
                                            {
                                                controls.count.map((item, index) => {
                                                    return <Col sm={6} key={index} onClick={() => item.onClick(1)}>{item.label}</Col>
                                                })
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                    }
                </div>
            </div>
        </Col>
    );
}

OrderBox.propTypes = {
    heading: PropTypes.string.isRequired,
    subheading: PropTypes.string.isRequired,
    warningType: PropTypes.string.isRequired,
    controls: PropTypes.object,
    printView: PropTypes.bool.isRequired,
    count: PropTypes.number.isRequired,
    countChildren: PropTypes.number.isRequired,
}

OrderBox.defaultProps = {
    warningType: '',
    subheading: '',
    md: 4,
    sm: 6,
    printView: false,
    count: 0,
    countChildren: 0
}

export default OrderBox;