import {
    REQUEST_PURCHASE,
    RECEIVE_PURCHASE,
    RECEIVE_LAST_PURCHASE,
    ERROR_PURCHASE
} from '../constants/types'
import {initialFetchPurchaseState} from '../constants/initialState'


export default (state = initialFetchPurchaseState, action = {}) => {
    switch (action.type) {
        case REQUEST_PURCHASE:
            return Object.assign({}, state, {
                isFetching: true,
                message: '',
                errors: []
            })
        case RECEIVE_PURCHASE:
            return Object.assign({}, state, {
                isFetching: false,
                purchaseDetail: action.data,
                lastPurchase: {},
                message: action.message
            })
        case RECEIVE_LAST_PURCHASE:
            return Object.assign({}, state, {
                isFetching: false,
                purchaseDetail: {},
                lastPurchase: action.data,
                message: action.message
            })
        case ERROR_PURCHASE:
            return Object.assign({}, state, {
                isFetching: false,
                errors: action.errors
            })
        default:
            return state;
    }
}