import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Row, Col } from "react-bootstrap"
import ForgottenForm from '../auth/ForgottenForm'
import { homepage } from '../../constants/constants'
import AuthDesign from '../auth/AuthDesign'
import {Redirect} from "react-router-dom";

class ForgottenPasswordPage extends React.Component {

    render() {
        if(this.props.auth.isAuthenticated){
            return <Redirect to={homepage}/>
        }
        return (
            <Row className="margin-h-0">
                <Col md={7} className="padding-h-0">
                    <div className="box-full box-theme">
                        <div className="centerizeVH">
                            <AuthDesign
                            text="Zadejte emailovou adresu a postupujte podle pokynů v obdrženém emailu."
                            />
                        </div>
                    </div>
                </Col>
                <Col md={5} className="padding-h-0">
                    <div className="box-full-supplement">
                        <div className="centerizeV height-100">
                            <ForgottenForm/>
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }
}

ForgottenPasswordPage.propTypes = {
    auth: PropTypes.object.isRequired,
}

ForgottenPasswordPage.contextTypes = {
    router: PropTypes.object.isRequired
}

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

export default connect(
    mapStateToProps,
    null
)(ForgottenPasswordPage);