import validator from 'validator'
import isEmpty from 'lodash/isEmpty'

export function validateInput(data) {
    let errors = {};

    if (validator.isEmpty(data.search)) {
        errors.search = 'Vyplňte pole';
    }

    return {
        errors,
        isValid: isEmpty(errors)
    };
}