import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'

class SessionInfo extends React.Component {
    render() {
        const session = this.props.activeSession.name
        return (
            <div className={classnames("session-info-outer", session ? "active" : "")}>
                <div className={classnames("session-info-inner", this.props.sessions.isFetching ? "inactive" : "")}>
                    aktivní {this.props.activeSession.pre_active ? "nákupní seznam" : "turnus"}:
                    <span> {session}</span>
                </div>
            </div>
        );
    }
}

SessionInfo.propTypes = {
    activeSession: PropTypes.object.isRequired,
    sessions: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    return {
        activeSession: state.activeSession,
        sessions: state.sessions
    };
}

export default connect(
    mapStateToProps,
    null
)(SessionInfo);