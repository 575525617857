import { SET_SEARCH_STRING } from '../constants/types'
import { searchString } from '../constants/initialState'


export default (state = searchString, action = {}) => {
    switch(action.type) {
        case SET_SEARCH_STRING:
            return action.string;
        default: return state;
    }
}