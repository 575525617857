import axios from 'axios'
import {
    REQUEST_CATEGORIES,
    RECEIVE_CATEGORIES,
    ERROR_CATEGORIES
} from '../constants/types'
import  {fetchUrl} from '../constants/constants'

function requestIngredientsCategories() {
    return {
        type: REQUEST_CATEGORIES
    };
}

function receiveIngredientsCategories(data, message = '') {
    return {
        type: RECEIVE_CATEGORIES,
        ingredientsCategories: data,
        message: message
    }
}

function failedIngredientsCategories(data) {
    return {
        type: ERROR_CATEGORIES,
        errors: data
    }
}

function shouldFetchIngredientsCategories(state, basedOnData = false) {
    const fetch = state.ingredientsCategories;
    if (!fetch) {
        return true
    } else if (fetch.isFetching) {
        return false
    } else if (basedOnData) {
        if(fetch.items.length == 0){
            return true
        }else{
            return false
        }
    } else {
        return true
    }
}

function doFetchIngredientsCategories(data, request) {
    return dispatch => {
        dispatch(requestIngredientsCategories());
        return axios.post(fetchUrl + request, data).then(res => {
            const response = res.data;
                if (response.data.success) {
                    dispatch(receiveIngredientsCategories(response.data.data, response.message))
                } else {
                    dispatch(failedIngredientsCategories([response.message]))
                }
            }).catch(err => {
                return dispatch(failedIngredientsCategories(["Neočekávaná chyba"]))
            })
    }
}

function fetchIngredientsCategories(type, data = {}) {
    let request;
    let compareData;
    switch (type) {
        case 'get':
            request = 'getIngredientsCategories';
            compareData = true;
            break;
        case 'add':
            request = 'addIngredientsCategory';
            compareData = false;
            break;
        case 'update':
            request = 'updateIngredientsCategory';
            compareData = false;
            break;
        case 'delete':
            request = 'deleteIngredientsCategory';
            compareData = false;
            break;
        default:
            request = 'getIngredientsCategories';
            compareData = true;
    }
    return (dispatch, getState) => {
        if (shouldFetchIngredientsCategories(getState(), compareData)) {
            return dispatch(doFetchIngredientsCategories(data, request))
        } else {
            return Promise.resolve()
        }
    }
}

export function getIngredientsCategories(data = {}){
    return fetchIngredientsCategories('get', data);
}

export function addIngredientsCategory(data = {}){
    return fetchIngredientsCategories('add', data);
}

export function updateIngredientsCategory(data = {}){
    return fetchIngredientsCategories('update', data);
}

export function deleteIngredientsCategory(data = {}){
    return fetchIngredientsCategories('delete', data);
}