import { createStore, applyMiddleware, compose } from 'redux'
import reducers from '../reducers'
import thunk from 'redux-thunk'

const finalCreateStore = compose(
  applyMiddleware(thunk)
)(createStore)

export function configureStore(initialState) {
  const store = finalCreateStore(reducers, initialState)

  if (module.hot) {
    module.hot.accept('../reducers', () =>
        store.replaceReducer(require('../reducers'))
    )
  }

  return store
}