import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {Col, Row} from "react-bootstrap"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Select from 'react-select'
import isEmpty from 'lodash/isEmpty'

const BoxAddSimple = ({fields, button, active, reset}) => {
    let barcodes = null;
    let onDelete = void(0);
    const inputs = fields.map((item, index) => {
            if (item.type === 'select') {
                if (!isEmpty(item.data)) {
                    return <Select
                        key={item.name}
                        name={item.name}
                        placeholder={item.label}
                        title={item.label}
                        value={item.value}
                        onChange={item.onChange}
                        options={item.data}
                        isDisabled={item.disabled}
                        className="react-select-container"
                        classNamePrefix="react-select"
                    />
                } else {
                    return null;
                }
            } else if (item.type == 'barcode') {
                if (Array.isArray(item.data)) {
                    barcodes = item.data;
                    onDelete = item.onDelete;
                    return null
                } else {
                    return <input
                        key={index}
                        className="form-control"
                        type='text'
                        placeholder={item.label}
                        title={item.label}
                        value={item.value}
                        onChange={item.onChange}
                        name={item.name}
                    />
                }
            } else if (item.type == 'checkbox') {
                return <div key={index}
                    className="checkbox abc-checkbox abc-checkbox-primary">
                    <input
                        type="checkbox"
                        name={item.name}
                        id={item.name}
                        checked={item.value}
                        onChange={item.onChange}
                    />
                    <label
                        htmlFor={item.name}
                    >
                        {item.label}
                    </label>
                </div>
            } else {
                return <input
                    key={index}
                    className="form-control"
                    type={item.type}
                    placeholder={item.label}
                    title={item.label}
                    value={item.value}
                    onChange={item.onChange}
                    name={item.name}
                    min={item.type == 'number' ? item.min : ''}
                />
            }
        }
    );


    return (
        <Col sm={12}>
            <div className={classnames("box", "no-hover", "vertical", active ? "active" : "")}>
                <div className="box-content">
                    {
                        active ?
                            <div className="reset-box" onClick={reset} title="Zrušit">
                                <FontAwesomeIcon icon="times"/>
                            </div>
                            :
                            null
                    }
                    <div className="box-inputs">
                        {inputs}
                    </div>
                    {
                        barcodes ?
                            <div className="box-barcodes">
                                <hr/>
                                <h4>{barcodes.length > 1 ? "Čárové kódy" : "Čárový kód"}</h4>
                                {
                                    barcodes.map((item, index) =>
                                        <Row key={index} className="barcode-item">
                                            <Col lg={3} md={4} sm={12}><span>{item.barcode}</span></Col>
                                            <Col lg={3} md={4} sm={12}>
                                                <button
                                                    onClick={() => onDelete(index)}
                                                    className="btn btn-theme"
                                                >odebrat
                                                </button>
                                            </Col>
                                        </Row>
                                    )
                                }
                            </div>
                            : null
                    }
                </div>
                <div className="box-icons-controls">
                    <div className="box-controls">
                        <div onClick={button.onClick}>{button.label}</div>
                    </div>
                </div>
            </div>
        </Col>
    );
}

BoxAddSimple.propTypes = {
    fields: PropTypes.array.isRequired,
    button: PropTypes.object.isRequired,
    reset: PropTypes.func.isRequired,
}

export default BoxAddSimple;