import {confirmAlert} from 'react-confirm-alert'

export function confirm(type, item, onTrue, onFalse){
    let title;
    switch(type){
        case 'delete':
            title = 'Opravdu chcete smazat položku';
            break;
        case 'add':
            title = 'Opravdu chcete přidat položku';
            break;
        case 'finalize':
            title = 'Opravdu chcete dokončit a uzavřít turnus';
            break;
        case 'deleteAll':
            title = 'Opravdu chcete smazat všechny objednávky';
            break;
        case 'duplicate':
            title = 'Opravdu chcete vytvořit kopii tohoto receptu';
            break;
        default:
            title = '';
    }

    confirmAlert({
        title: title,
        message: item,
        buttons: [
            {
                label: 'Cancel',
                onClick: () => onFalse()
            },
            {
                label: 'Ano',
                onClick: () => onTrue()
            }
        ]
    })
}
