import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CloseButton = ({ target }) => {
    return (
        <div>
            <Link to={target} className="close-icon-wrapper">
                <FontAwesomeIcon icon={['far', 'times-circle']} className="close-icon" size="2x"/>
                <FontAwesomeIcon icon={['fas', 'times-circle']} className="close-icon-hover" size="2x"/>
            </Link>
        </div>
    );
}

CloseButton.propTypes = {
    target: PropTypes.string.isRequired
}

export default CloseButton;