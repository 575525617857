import axios from 'axios'
import {
    REQUEST_PURCHASE,
    RECEIVE_PURCHASE,
    RECEIVE_LAST_PURCHASE,
    ERROR_PURCHASE
} from '../constants/types'
import  {fetchUrl} from '../constants/constants'
import {receiveIngredients} from './IngredientsActions'
import {reloadOrders} from './OrdersActions'
import {reloadWarehouseStatus} from './WarehouseStatusActions'
import {getWarehouse} from './WarehouseActions'
import isEmpty from 'lodash/isEmpty'

function requestPurchase() {
    return {
        type: REQUEST_PURCHASE
    };
}

function receivePurchase(data, message = '') {
    return {
        type: RECEIVE_PURCHASE,
        data: data,
        message: message
    }
}

function receiveLastPurchase(data, message = '') {
    return {
        type: RECEIVE_LAST_PURCHASE,
        data: data,
        message: message
    }
}

function failedPurchase(data) {
    return {
        type: ERROR_PURCHASE,
        errors: data
    }
}

function shouldFetchPurchase(state, basedOnData = false) {
    const fetch = state.purchaseDetail;
    if (!fetch) {
        return true
    } else if (fetch.isFetching) {
        return false
    } else if (fetch.shouldReload) {
        return true
    } else if (basedOnData) {
        if(isEmpty(fetch.detail)){
            return true
        }else{
            return false
        }
    } else {
        return true
    }
}

function doFetchPurchase(data, request) {
    return dispatch => {
        dispatch(requestPurchase());
        return axios.post(fetchUrl + request, data).then(res => {
            const response = res.data;
                if (response.data.success) {
                    if(request == 'getLastPurchase'){
                        dispatch(receiveLastPurchase(response.data.data.last, response.message));
                    }else{
                        dispatch(receivePurchase(response.data.data.detail, response.message));
                        if(!isEmpty(response.data.data.ingredients)){
                            dispatch(receiveIngredients(response.data.data.ingredients))
                        }
                    }
                    if(request == 'addPurchase' || request == 'updatePurchase'){
                        dispatch(getWarehouse({}, true))
                        dispatch(reloadWarehouseStatus())
                        dispatch(reloadOrders())
                    }
                } else {
                    dispatch(failedPurchase([response.message]))
                }
            }).catch(err => {
                return dispatch(failedPurchase(["Neočekávaná chyba"]))
            })
    }
}

function fetchPurchase(type, data = {}) {
    let request;
    let compareData;
    switch (type) {
        case 'getDetail':
            request = 'getPurchaseDetail';
            compareData = false;
            break;
        case 'add':
            request = 'addPurchase';
            compareData = false;
            break;
        case 'update':
            request = 'updatePurchase';
            compareData = false;
            break;
        case 'getLast':
            request = 'getLastPurchase';
            compareData = false;
            break;
        default:
            request = 'getPurchaseDetail';
            compareData = false;
    }
    return (dispatch, getState) => {
        if (shouldFetchPurchase(getState(), compareData)) {
            return dispatch(doFetchPurchase(data, request))
        } else {
            return Promise.resolve()
        }
    }
}

export function getPurchaseDetail(data = {}){
    return fetchPurchase('getDetail', data);
}

export function addPurchase(data = {}){
    return fetchPurchase('add', data);
}

export function updatePurchase(data = {}){
    return fetchPurchase('update', data);
}

export function getLastPurchase(data = {}){
    return fetchPurchase('getLast', data);
}