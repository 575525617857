import { SET_ACTIVE_MENU_ITEM } from '../constants/types'
import { activeMenuItem } from '../constants/initialState'


export default (state = activeMenuItem, action = {}) => {
    switch(action.type) {
        case SET_ACTIVE_MENU_ITEM:
            return action.item;
        default: return state;
    }
}