import React from 'react'
import PropTypes from 'prop-types'
import {Row, Col} from "react-bootstrap"
import ReactJson from 'react-json-view'

class LogItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            parsed: {
                input: false,
                output: false
            }
        }
    }

    onParse(type) {
        const parsed = this.state.parsed
        parsed[type] = true
        this.setState({parsed: parsed});
    }

    render() {
        const parsed = this.state.parsed
        const data = this.props.data
        let browser = {}
        if (data.browser) {
            try {
                browser = JSON.parse(data.browser)
            } catch (e) {
                console.log(e)
            }
        }
        let input, output = <p>žádná data</p>
        try {
            if(data.input){
                input = parsed.input ?
                    <ReactJson
                        src={JSON.parse(data.input)}
                        theme="rjv-default"
                        iconStyle="square"
                        displayObjectSize={false}
                        displayDataTypes={false}
                    />
                    :
                    <button className="btn btn-theme" onClick={() => this.onParse('input')}>zobrazit data</button>
            }
        } catch (e) {
            input = <p>nevalidní json</p>
            console.log(e)
        }
        try {
            if(data.data){
                output = parsed.output ?
                    <ReactJson
                        src={JSON.parse(data.data)}
                        theme="rjv-default"
                        iconStyle="square"
                        displayObjectSize={false}
                        displayDataTypes={false}
                    />
                    :
                    <button className="btn btn-theme" onClick={() => this.onParse('output')}>zobrazit data</button>
            }
        } catch (e) {
            output = <p>nevalidní json</p>
            console.log(e)
        }

        return (
            <React.Fragment>
                <h3>
                    <small><strong>[{data.id}] </strong></small>
                    {data.request}
                    <small>({data.message})</small>
                </h3>
                <h5>{data.date}</h5>
                <div>source: {browser.name}, {browser.version}, {browser.os}, {data.ip}</div>
                <hr/>
                <div>
                    <Row>
                        <Col md={6}>
                            <h3>input:</h3>
                            {input}
                        </Col>
                        <Col md={6}>
                            <h3>output:</h3>
                            {output}
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        )
    }
}

LogItem.propTypes = {
    data: PropTypes.object.isRequired
}

export default LogItem