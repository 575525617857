import {
    REQUEST_INGREDIENTS,
    RECEIVE_INGREDIENTS,
    ERROR_INGREDIENTS
} from '../constants/types'
import { initialFetchState } from '../constants/initialState'


export default (state = initialFetchState, action = {}) => {
    switch(action.type) {
        case REQUEST_INGREDIENTS:
            return Object.assign({}, state, {
                isFetching: true,
                message: '',
                errors: []
            })
        case RECEIVE_INGREDIENTS:
            return Object.assign({}, state, {
                isFetching: false,
                items: action.ingredients,
                message: action.message
            })
        case ERROR_INGREDIENTS:
            return Object.assign({}, state, {
                isFetching: false,
                errors: action.errors
            })
        default: return state;
    }
}