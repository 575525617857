import React, {Component} from 'react'
import {Row, Col} from "react-bootstrap"
import ChangeForm from '../auth/ChangeForm'
import AuthDesign from '../auth/AuthDesign'
import CloseButton from '../common/CloseButton'

class ChangePasswordPage extends Component {

    render() {

        return (
            <Row className="margin-h-0">
                <Col md={7} className="padding-h-0">
                    <div className="box-full box-theme">
                        <div className="centerizeVH">
                            <AuthDesign
                                text="Pro změnu hesla zadejte nejprve vaše současné heslo a poté nové"
                            />
                        </div>
                    </div>
                </Col>
                <Col md={5} className="padding-h-0">
                    <div className="box-full-supplement">
                        <div className="centerizeV height-100">
                            <ChangeForm/>
                            <CloseButton target="/profil"/>
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }
}

export default ChangePasswordPage;