import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import {navigation} from '../../constants/constants'

class Subnavigation extends React.Component {

    render() {
        const subnavigation = navigation[this.props.navigation].subnavigation.map((item, i) => {
            if(item.identifier == this.props.navigation){
                return(
                    <li key={i} className="active">
                        <span>
                            {item.title}
                        </span>
                    </li>
                )
            }else{
                return(
                    <li key={i}>
                        <Link to={item.link}>
                            {item.title}
                        </Link>
                    </li>
                )
            }
            }
        );

        return (
            <div className="sub-navigation">
                <ul>
                    {subnavigation}
                </ul>
            </div>
        );
    }
}

Subnavigation.propTypes = {
    navigation: PropTypes.string.isRequired
}

function mapStateToProps(state) {
    return {
        navigation: state.navigation
    };
}

export default connect(
    mapStateToProps,
    null
)(Subnavigation);