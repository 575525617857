import axios from 'axios'
import {
    REQUEST_WAREHOUSE_STATUS,
    RECEIVE_WAREHOUSE_STATUS,
    ERROR_WAREHOUSE_STATUS,
    RELOAD_WAREHOUSE_STATUS
} from '../constants/types'
import  {fetchUrl} from '../constants/constants'
import {receiveIngredients} from './IngredientsActions'
import {reloadOrders} from './OrdersActions'
import {getWarehouse} from './WarehouseActions'
import isEmpty from 'lodash/isEmpty'

function requestWarehouseStatus() {
    return {
        type: REQUEST_WAREHOUSE_STATUS
    };
}

function receiveWarehouseStatus(data, message = '') {
    return {
        type: RECEIVE_WAREHOUSE_STATUS,
        data: data,
        message: message
    }
}

function failedWarehouseStatus(data) {
    return {
        type: ERROR_WAREHOUSE_STATUS,
        errors: data
    }
}

export function reloadWarehouseStatus() {
    return {
        type: RELOAD_WAREHOUSE_STATUS
    }
}

function shouldFetchWarehouseStatus(state, basedOnData = false) {
    const fetch = state.warehouseStatus;
    if (!fetch) {
        return true
    } else if (fetch.isFetching) {
        return false
    } else if (fetch.shouldReload) {
        return true
    } else if (basedOnData) {
        if (isEmpty(fetch.items)) {
            return true
        } else {
            return false
        }
    } else {
        return true
    }
}

function doFetchWarehouseStatus(data, request) {
    return dispatch => {
        if (request != 'printWarehouse') {
            dispatch(requestWarehouseStatus());
        }
        return axios.post(fetchUrl + request, data).then(res => {
            const response = res.data;
            if (request != 'printWarehouse') {
                if (response.data.success) {
                    if (request == 'addWarehouseCorrection') {
                        dispatch(receiveWarehouseStatus(response.data.data.newData, response.message));
                        if (!isEmpty(response.data.data.ingredients)) {
                            dispatch(receiveIngredients(response.data.data.ingredients))
                        }
                        dispatch(getWarehouse({}, true))
                        dispatch(reloadOrders())
                    } else {
                        dispatch(receiveWarehouseStatus(response.data.data, response.message));
                    }
                } else {
                    dispatch(failedWarehouseStatus([response.message]))
                }
            } else {
                if (response.data.success) {
                    var popUp = window.open(response.data.data.target);
                    if (popUp == null || typeof(popUp) == 'undefined') {
                        alert('Otevření souboru bylo zablokováno prohlížečem. Povolte otevírání PopUp oken pro tuto doménu a akci opakujte');
                    } else {
                        popUp.focus();
                    }
                }
            }
        }).catch(err => {
            return dispatch(failedWarehouseStatus(["Neočekávaná chyba"]))
        })
    }
}

function fetchWarehouseStatus(type, data = {}) {
    let request;
    let compareData;
    switch (type) {
        case 'get':
            request = 'getWarehouseStatus';
            compareData = true;
            break;
        case 'correct':
            request = 'addWarehouseCorrection';
            compareData = false;
            break;
        case 'print':
            request = 'printWarehouse';
            compareData = false;
            break;
        default:
            request = 'getWarehouseStatus';
            compareData = false;
    }
    return (dispatch, getState) => {
        if (shouldFetchWarehouseStatus(getState(), compareData)) {
            return dispatch(doFetchWarehouseStatus(data, request))
        } else {
            return Promise.resolve()
        }
    }
}

export function getWarehouseStatus(data = {}) {
    return fetchWarehouseStatus('get', data);
}

export function addWarehouseCorrection(data = {}) {
    return fetchWarehouseStatus('correct', data);
}

export function printWarehouse(data = {}) {
    return fetchWarehouseStatus('print', data);
}
