import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const AuthInput = ({ field, value, label, type, onChange, icon,  errors }) => {
    const fontAwesomeIcon = icon ? <FontAwesomeIcon icon={icon} className="input-icon" size="2x"/> : '';
    return (
        <div>
            {fontAwesomeIcon}
            <input
                onChange={onChange}
                value={value}
                type={type}
                name={field}
                placeholder={label}
                className={classnames("form-control", field in errors ? "has-error" : "")}
            />
        </div>
      );
}

AuthInput.propTypes = {
    field: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    errors: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    icon: PropTypes.string,
    onChange: PropTypes.func.isRequired,
}

AuthInput.defaultProps = {
    type: 'text',
    errors : {}
}

export default AuthInput;