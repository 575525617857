import axios from 'axios'
import {
    REQUEST_ORDERS,
    RECEIVE_ORDERS,
    ERROR_ORDERS,
    RELOAD_ORDERS
} from '../constants/types'
import  {fetchUrl} from '../constants/constants'
import {reloadRecipes} from './RecipesActions'
import {reloadWarehouseStatus} from './WarehouseStatusActions'
import {reloadWarehouse} from './WarehouseActions'

function requestOrders() {
    return {
        type: REQUEST_ORDERS
    };
}

function receiveOrders(data, message = '') {
    return {
        type: RECEIVE_ORDERS,
        orders: data,
        message: message
    }
}

function failedOrders(data) {
    return {
        type: ERROR_ORDERS,
        errors: data
    }
}

export function reloadOrders(){
    return {
        type: RELOAD_ORDERS
    }
}

function shouldFetchOrders(state, basedOnData = false) {
    const fetch = state.orders;
    if (!fetch) {
        return true
    } else if (fetch.isFetching) {
        return false
    } else if (fetch.shouldReload) {
        return true
    } else if (basedOnData) {
        if (fetch.items.length == 0) {
            return true
        } else {
            return false
        }
    } else {
        return true
    }
}

function doFetchOrders(data, request) {
    return dispatch => {
        if (request != "printOrders") {
            dispatch(requestOrders());
        }
        return axios.post(fetchUrl + request, data).then(res => {
            const response = res.data;
            if (request != "printOrders") {
                if (response.data.success) {
                    dispatch(receiveOrders(response.data.data, response.message));
                    if(request != 'getOrders'){
                        dispatch(reloadRecipes()); // edit enabled check
                        dispatch(reloadWarehouseStatus()); // warehouse status changed
                        dispatch(reloadWarehouse()); // warehouse changed
                    }
                } else {
                    dispatch(failedOrders([response.message]))
                }
            } else {
                if (response.data.success) {
                    var popUp = window.open(response.data.data.target);
                    if (popUp == null || typeof(popUp) == 'undefined') {
                        alert('Otevření souboru bylo zablokováno prohlížečem. Povolte otevírání PopUp oken pro tuto doménu a akci opakujte');
                    } else {
                        popUp.focus();
                    }

                }
            }
        }).catch(err => {
            return dispatch(failedOrders(["Neočekávaná chyba"]))
        })
    }
}

function fetchOrders(type, data = {}) {
    let request;
    let compareData;
    switch (type) {
        case 'get':
            request = 'getOrders';
            compareData = true;
            break;
        case 'add':
            request = 'addOrder';
            compareData = false;
            break;
        case 'delete':
            request = 'deleteOrder';
            compareData = false;
            break;
        case 'deleteAll':
            request = 'deleteAllOrders';
            compareData = false;
            break;
        case 'print':
            request = 'printOrders';
            compareData = false;
            break;
        default:
            request = 'getOrders';
            compareData = false;
    }
    return (dispatch, getState) => {
        if (shouldFetchOrders(getState(), compareData)) {
            return dispatch(doFetchOrders(data, request))
        } else {
            return Promise.resolve()
        }
    }
}

export function getOrders(data = {}) {
    return fetchOrders('get', data);
}

export function addOrder(data = {}) {
    return fetchOrders('add', data);
}

export function deleteOrder(data = {}) {
    return fetchOrders('delete', data);
}
export function deleteAllOrders(data = {}) {
    return fetchOrders('deleteAll', data);
}

export function printOrders(data = {}) {
    return fetchOrders('print', data);
}